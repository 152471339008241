import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { RootState } from "../../Store/store";
import "./CallRequestModal.css";
import {setIsCallRequestModalOpened} from "../../Store/Reducers/pageReducer";
import {createUserRequest} from "../../Api/userRequestsApi/userRequestsApi";
import ThreeIcons from "../../Utils/ThreeIcons/ThreeIcons";

const CallRequestModal = () => {
    const dispatch = useDispatch();

    const isCallRequestModalOpened = useSelector(
        (state: RootState) => state.page.isCallRequestModalOpened
    );

    const [disabled, setDisabled] = useState(false);
    const [textButton, setTextButton] = useState('ЗВ\'ЯЖІТЬСЯ ЗІ МНОЮ');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhone(event.target.value);
    };

    const handleSubmit = async () => {
        if (name.trim() !== '' || phone.trim() !== '') {
            await createUserRequest(name, phone);
            setTextButton("Вашу заявку оброблено!")
            setDisabled(true);
        }
    };

    useEffect(() => {
        if (isCallRequestModalOpened) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }

        return () => {
            document.body.style.overflow = "";
        };
    }, [isCallRequestModalOpened]);

    return (
        <div className="callRequestModalWrapper">
            <div className={"callRequestModalContent"}>
                <span className={"callRequestModalTitle"}>ОТРИМАЙТЕ КОНСУЛЬТАЦІЮ</span>
                <input className={"consultInput"} placeholder={"Ім'я"} value={name} onChange={handleNameChange} />
                <input className={"consultInput"} placeholder={"Телефон"} value={phone} onChange={handlePhoneChange} />
                <button
                    className={"callRequestModalBtn"}
                    onClick={handleSubmit}
                    disabled={disabled}
                >
                    {textButton}
                </button>
                <button
                    className={"callRequestModalCloseBtn"}
                    onClick={() => dispatch(setIsCallRequestModalOpened(false))}>
                    <svg
                        fill="#fff"
                        width="30px"
                        height="30px"
                        viewBox="0 0 24 24"
                        id="cross"
                        data-name="Line Color"
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon line-color"
                    >
                        <line
                            id="primary"
                            x1={19}
                            y1={19}
                            x2={5}
                            y2={5}
                            style={{
                                fill: "none",
                                stroke: "rgb(255, 255, 255)",
                                strokeLinecap: "round",
                                strokeLinejoin: "round",
                                strokeWidth: 2,
                            }}
                        />
                        <line
                            id="primary-2"
                            data-name="primary"
                            x1={19}
                            y1={5}
                            x2={5}
                            y2={19}
                            style={{
                                fill: "none",
                                stroke: "rgb(255, 255, 255)",
                                strokeLinecap: "round",
                                strokeLinejoin: "round",
                                strokeWidth: 2,
                            }}
                        />
                    </svg>
                </button>
            </div>
        </div>
    );
};

export default CallRequestModal;
