import FlexContainer from "../../Utils/FlexContainer/FlexContainer";
import Example from "../../Images/Example.png";
import Text from "../../Utils/Text/Text";
import Counter from "../../Utils/Counter/Counter";
import React from "react";
import {ReactComponent as Close} from "../../Images/Icons/Close.svg";
import {ProductOrder} from "../../Types";
import {useDispatch} from "react-redux";
import {removeFromCart} from "../../Store/Reducers/basketReducer";

import "./BasketItem.css";

interface InfoBlockProps {
    label: string;
    value: string;
    isAlternate: boolean;
}

interface BasketItemProps {
    item: ProductOrder;
}

const InfoBlock: React.FC<InfoBlockProps> = ({ label, value, isAlternate }) => {
    const backgroundColor = isAlternate ? "#F3F3F3" : "transparent";
    const textColor = isAlternate ? "#595A5A" : "#595A5A";

    return (
        <div
            style={{display: "flex", justifyContent: "space-between", alignItems: "center", gap: 40, padding: "10px 20px 10px 0", backgroundColor, width: "100%"}}>
            <Text className={"bodyW3"} color={textColor} styles={{ marginLeft: 20, width: 168 }}>{label}</Text>
            <Text className={"bodyW3"} color={textColor}>{value}</Text>
        </div>
    );
};

const BasketItem: React.FC<BasketItemProps> = ({item}) => {
    const dispatch = useDispatch();


    const countChange = () => {

    }

    const onClose = () => {
        dispatch(removeFromCart(item));
    };

    const infoBlocks = [
        { label: "Ширина", value: item.width },
        { label: "Довжина", value: item.len },
        { label: "Товщина", value: item.thickness },
        { label: "Діаметр", value: item.diameter },
        { label: "Діаметр 1", value: item.diameter1 },
        { label: "Діаметр 2", value: item.diameter2 },
        { label: "Градус", value: item.angle },
        { label: "Тип", value: item.item_type }
    ];

    let isAlternate = false;
    const blocks = infoBlocks
        .filter(block => block.value)
        .map((block, index) => {
            isAlternate = !isAlternate;
            return <InfoBlock key={index} label={block.label} value={block.value!} isAlternate={isAlternate} />;
        });

    return (
        <div className={"basketItemWrapper"}>
            <div className={"basketItemHeader"}>
                <img
                    src={item.img_url}
                    alt={"Not found"}
                />
                <div className={"basketItemDesc"}>
                    <span className={"basketItemTitle"}>{item.name}</span>
                    <Counter onCountChange={countChange} counter={item.quantity}/>
                </div>
            </div>
            <div className={"basketItemBlocks"}>
                <div className={"basketItemDescTablet"}>
                    <span className={"basketItemTitle"}>{item.name}</span>
                    <Counter onCountChange={countChange} counter={item.quantity}/>
                </div>
                {blocks}
            </div>
            <Close onClick={onClose} style={{cursor: "pointer", position: "absolute", top: -20, right: -10}}/>
        </div>
    )
}

export default BasketItem