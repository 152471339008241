import instance from "../instanse";

export const createUserRequest = async (name: string, phone: string) => {
    try {
        const response = await instance.post('/user_requests', {
            name: name,
            phone: phone
        });
        return response.data
    } catch (error) {
        console.error('Error fetching products:', error);
    }
};

export const createPriceRequest = async (data: any) => {
    try {
        const response = await instance.post('/user_price_requests', data);
        return response.data
    } catch (error) {
        console.error('Error fetching products:', error);
    }
};

export const createOrder = async (data: any) => {
    try {
        const response = await instance.post('/user_orders', data);
        return response.data
    } catch (error) {
        console.error('Error fetching products:', error);
    }
};

export const getAllRequests = async (skip: number, limit: number) => {
    try {
        const response = await instance.get('/user_requests', {
            headers: {
                Authorization: `${localStorage.getItem("token")}`,
            },
            params: {
                skip: skip,
                limit: limit,
            },
        });
        return response.data
    } catch (error) {
        console.error('Error fetching products:', error);
    }
};

export const getAllPriceRequests = async (skip: number, limit: number) => {
    try {
        const response = await instance.get('/user_price_requests', {
            headers: {
                Authorization: `${localStorage.getItem("token")}`,
            },
            params: {
                skip: skip,
                limit: limit,
            },
        });
        return response.data
    } catch (error) {
        console.error('Error fetching price requests:', error);
    }
};

export const getAllOrders = async (skip: number, limit: number) => {
    try {
        const response = await instance.get('/user_orders', {
            headers: {
                Authorization: `${localStorage.getItem("token")}`,
            },
            params: {
                skip: skip,
                limit: limit,
            },
        });
        return response.data
    } catch (error) {
        console.error('Error fetching price orders:', error);
    }
};

export const getAllCategories = async () => {
    try {
        const response = await instance.get('/items/category');
        return response.data
    } catch (error) {
        console.error('Error fetching categories:', error);
    }
};

export const getAllSubcategories = async () => {
    try {
        const response = await instance.get('/items/subcategory');
        return response.data
    } catch (error) {
        console.error('Error fetching subcategories:', error);
    }
};
