import React, {useEffect, useState} from 'react';
import {OrderRequest} from "../../../../Types";
import {getAllOrders, getAllPriceRequests} from "../../../../Api/userRequestsApi/userRequestsApi";

const OrderTable: React.FC = () => {
    const [requests, setRequests] = useState<OrderRequest[]>([]);
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    });

    const fetchRequests = async () => {
        const data = await getAllOrders((pagination.page - 1) * pagination.limit, pagination.limit);
        setRequests(data.items);
    };

    useEffect(() => {
        fetchRequests();
    }, [pagination]);

    const handlePaginationChange = (newPage: number) => {
        setPagination((prev) => ({ ...prev, page: newPage }));
    };

    const handlePageSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newLimit = parseInt(event.target.value, 10);
        setPagination((prev) => ({ ...prev, limit: newLimit, page: 1 }));
    };

    return (
        <>
        <div className="table-container">
            <table>
                <thead>
                <tr>
                    <th>Ім'я</th>
                    <th>Спосіб зв'язку</th>
                    <th>Додаткова інф.</th>
                    <th>Товари</th>
                </tr>
                </thead>
                <tbody>
                {requests.map((request) => (
                    <tr key={request.id}>
                        <td>{request.username}</td>
                        <td>{request.contactInfo}</td>
                        <td>{request.extraInfo}</td>
                        <td>{request.items}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
        <div className="pagination">
            <button onClick={() => handlePaginationChange(pagination.page - 1)} disabled={pagination.page === 1}>
                Назад
            </button>
            <span>{pagination.page}</span>
            <button onClick={() => handlePaginationChange(pagination.page + 1)}>Вперед</button>
        </div>
        <div className="page-size">
            <label htmlFor="pageSize">Записів на сторінці:</label>
            <select id="pageSize" value={pagination.limit} onChange={handlePageSizeChange}>
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
            </select>
        </div>
        </>
    );
};

export default OrderTable;

