import React, {useEffect, useState} from 'react';
import "./../AdminForms.css";
import instance from "../../../../Api/instanse";
import {Product, Subcategory} from "../../../../Types";
import {DragArea} from "../../../../Utils/DragArea/DragArea";
import {getAllSubcategories} from "../../../../Api/userRequestsApi/userRequestsApi";
import {CreateProduct} from "../../../../Types";
import {deleteItemImage} from "../../../../Api/ItemsApi/ItemsApi";

interface EditProductModalProps {
    item?: Product;
    onClose: () => void;
}

const EditProductModal: React.FC<EditProductModalProps> = ({ item, onClose }) => {
    const initialProduct: CreateProduct = {
        name: item?.name ? item.name : '',
        description: item?.description ? item.description : '',
        images: item?.images ? item.images : [],
        subcategory_id: item?.subcategory_id ? item.subcategory_id : 0,
        width: item?.width ? item.width : '',
        len: item?.len ? item.len : '',
        thickness: item?.thickness ? item.thickness : '',
        diameter: item?.diameter ? item.diameter : '',
        diameter1: item?.diameter1 ? item.diameter1 : '',
        diameter2: item?.diameter2 ? item.diameter2 : '',
        angle: item?.angle ? item.angle : '',
        item_type: item?.item_type ? item.item_type : '',
    };
    const [product, setProduct] = useState(initialProduct);
    const [images, setImages] = useState<File[]>([]);
    const [subcategories, setSubcategories] = useState<Subcategory[]>([]);

    useEffect(() => {
        const fetchSubcategories = async () => {
            const data = await getAllSubcategories();
            setSubcategories(data.subcategories);
        };
        fetchSubcategories();
    }, []);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value} = event.target;
        setProduct((prevProduct) => ({
            ...prevProduct,
            [name]: value,
        }));
    };

    const handleTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setProduct((prevProduct) => ({
            ...prevProduct,
            [name]: value,
        }));
    };

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = event.target;
        setProduct((prevProduct) => ({
            ...prevProduct,
            [name]: value,
        }));
    };

    const handleSaveProduct = async () => {
        const formData = new FormData();
        formData.append('name', product.name);
        formData.append('description', product.description);
        formData.append('subcategory_id', String(product.subcategory_id));
        formData.append('width', String(product.width));
        formData.append('len', String(product.len));
        formData.append('thickness', String(product.thickness));
        formData.append('diameter', String(product.diameter));
        formData.append('diameter1', String(product.diameter1));
        formData.append('diameter2', String(product.diameter2));
        formData.append('angle', String(product.angle));
        formData.append('item_type', String(product.item_type));
        images.forEach((file, index) => {
            formData.append(`files`, file);
        });
        if (item) {
            console.log(formData)
            try {
                await instance.patch(
                    `/items/${item.id}`,
                    formData,
                    {
                        headers: {
                            Authorization: `${localStorage.getItem("token")}`,
                            Accept: 'application/json',
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
                onClose();
            } catch (error) {
                console.error('Create/Update record error:', error);
            }
        } else {
            try {
                await instance.post(
                    `/items`,
                    formData,
                    {
                        headers: {
                            Authorization: `${localStorage.getItem("token")}`,
                            Accept: 'application/json',
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
                onClose();
            } catch (error) {
                console.error('Create/Update record error:', error);
            }
        }
    };

    const handleAddImages = (newImages: FileList | null) => {
        if (newImages) {
            const filesArray = Array.from(newImages);
            setImages((prevFiles) => [...prevFiles, ...filesArray])
            setProduct((prevProduct) => ({
                ...prevProduct,
                images: [...prevProduct.images, ...filesArray.flatMap((file) => file.name)],
            }));
        }
    }

    const handleRemoveImage = async (name: string) => {
        if (item && item.id && name.startsWith('http')) {
            await deleteItemImage(item.id, name);
        }

        const updatedImages = images.filter(image => image.name !== name);
        setImages(updatedImages);

        const updatedProductImages = product.images.filter(image => image !== name);
        setProduct(prevProduct => ({
            ...prevProduct,
            images: updatedProductImages,
        }));
    }

    return (
        <div className="modal">
            <div className="modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <h2>{'Додати новий товар'}</h2>

                <label>Назва:</label>
                <input type="text" name="name" value={product.name} onChange={handleInputChange} />

                <label>Опис:</label>
                <textarea name="description" value={product.description} onChange={handleTextareaChange}/>

                <label>Ширина:</label>
                <textarea name="width" value={product.width} onChange={handleTextareaChange}/>

                <label>Довжина:</label>
                <textarea name="len" value={product.len} onChange={handleTextareaChange}/>

                <label>Товщина:</label>
                <textarea name="thickness" value={product.thickness} onChange={handleTextareaChange}/>

                <label>Діаметр:</label>
                <textarea name="diameter" value={product.diameter} onChange={handleTextareaChange}/>

                <label>Діаметр1:</label>
                <textarea name="diameter1" value={product.diameter1} onChange={handleTextareaChange}/>

                <label>Діаметр2:</label>
                <textarea name="diameter2" value={product.diameter2} onChange={handleTextareaChange}/>

                <label>Кут:</label>
                <textarea name="angle" value={product.angle} onChange={handleTextareaChange}/>

                <label>Тип:</label>
                <textarea name="item_type" value={product.item_type} onChange={handleTextareaChange}/>

                <label>Картинки:</label>
                <DragArea onAdd={handleAddImages}/>
                <div className={"imagesList"}>
                    {product.images.map((image, index) => (
                        <div className={"imageItem"}>
                            <span>{image}</span>
                            <h1
                                onClick={() => handleRemoveImage(image)}
                                style={{cursor: "pointer"}}
                            >&times;</h1>
                        </div>
                    ))}
                </div>

                <label>Категорія:</label>
                <select name="subcategory_id" value={product.subcategory_id} onChange={handleSelectChange}>
                    <option value="">Оберіть підкатегорію</option>
                    {subcategories.map(subcategory => (
                        <option key={subcategory.id} value={subcategory.id}>{subcategory.name}</option>
                    ))}
                </select>

                <button onClick={handleSaveProduct}>{item ? 'Редагувати' : 'Створити'}</button>
            </div>
        </div>
    );
};

export default EditProductModal;
