import React, {FC} from "react";
import PriceRequestTable from "../Tables/PriceRequestTable/PriceRequestTable";


export const AdminPriceRequests: FC = () => {
    return (
        <>
            <div className={"adminPanelHeader"}>
                <span className={"adminCategoriesHeader"}>Запити на ціну</span>
            </div>
            <div className={"adminPanelContent"}>
                <PriceRequestTable></PriceRequestTable>
            </div>
        </>
    );
};
