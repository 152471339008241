import React from 'react';
import Button from "../../Utils/Button/Button";
import "./Home.css";
import ThreeIcons from "../../Utils/ThreeIcons/ThreeIcons";
import ServiceCard from "../../Utils/ServiceCard/ServiceCard";
import RedirectButton from "../../Utils/RedirectButton/RedirectButton";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ReactComponent as NextArrow } from "../../Images/Icons/NextArrow.svg";
import { ReactComponent as PreviousArrow } from "../../Images/Icons/PreviousArrow.svg";
import Text from "../../Utils/Text/Text";

import Header from "../../Images/HomePage/Header1.png";
import AboutPhone from "../../Images/HomePage/aboutPhone.png";
import AboutBG from "../../Images/HomePage/AboutBG.png";
import Customers from "../../Images/HomePage/Customers.png";
import customersColumns from "../../Images/HomePage/customersColumn.png";
import LogoBig from "../../Images/Logos/Logo_big.png";
import {useMedia} from "use-media";
import Footer from "../../Components/Footer/Footer";
import Consult from "../../Components/Cosultate/Consult";
import {setIsCallRequestModalOpened} from "../../Store/Reducers/pageReducer";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

export function Home() {
    const isPhone = useMedia("(max-width: 770px)");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const settings = {
        slidesToShow: 4,
        slidesToScroll: 2,
        infinite: true,
        centerMode: true,
        centerPadding: '40px',
        prevArrow: <PreviousArrow />,
        nextArrow: <NextArrow />,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    arrows: false,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    centerMode: true,
                    centerPadding: '40px'
                }
            },
            {
                breakpoint: 950,
                settings: {
                    arrows: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    centerMode: true,
                    centerPadding: '40px'
                }
            },
            {
                breakpoint: 700,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    centerMode: true,
                    centerPadding: '40px'
                }
            }
        ]
    };

    const aboutInfoData = [
        { number: '01', text: 'Необхідні допуски на виконання робіт' },
        { number: '02', text: 'Власне виробництво' },
        { number: '03', text: 'Сучасне програмне забезпечення' },
        { number: '04', text: 'Автоматизації інженерних систем' },
    ];

    return (
        <>
            <div className={"homeHeaderWrapper"}
                 style={{
                     backgroundImage: `url(${Header})`,
                     backgroundPosition: 'right',
                     backgroundRepeat: 'no-repeat'
                 }}
            >
                <RedirectButton text="ПРО КОМПАНІЮ" url={"/about"}/>
                <div className={"homeHeaderContent"}>
                    <span className={"homeHeaderTitle"}>РОБОТИ ЗІ СПЕЦІАЛІЗОВАНОГО <br className={"divider"}/> БУДІВНИЦТВА СПОРУД</span>
                    <span className={"homeHeaderDesc"}>
                        Реалізуємо складні проекти, підбираємо та виготовляємо необхідне обладнання
                    </span>
                    <div className={"homeHeaderButtonsBlock"}>
                        <div className={"homeHeaderButtons"}>
                            <button className={"homeHeaderFirstBtn"} onClick={() => dispatch(setIsCallRequestModalOpened(true))}>ОБГОВОРИТИ ПРОЕКТ</button>
                            <button className={"homeHeaderSecondBtn"} onClick={() => navigate("/services")}>БІЛЬШЕ ПРО ПОСЛУГИ</button>
                        </div>
                        <ThreeIcons />
                    </div>
                </div>
            </div>

            <div className="slider-container">
                <Slider {...settings} className="service-slider">
                    <ServiceCard text="Зведення будинків та споруд" url="/services" button_text="ДЕТАЛЬНІШЕ" />
                    <ServiceCard text="Постачання монтаж та підбір ОВК обладнання" url="/services" button_text="ДЕТАЛЬНІШЕ" />
                    <ServiceCard text="Інноваційний відеонагляд" url="/services" button_text="ДЕТАЛЬНІШЕ" />
                    <ServiceCard text="Виробництво поітропроводів та димоходів" url="/services" button_text="ДЕТАЛЬНІШЕ" />
                    <ServiceCard text="Виробництво кондционерів транспорту" url="/services" button_text="ДЕТАЛЬНІШЕ" />
                    <ServiceCard text="Виробництво щитів автоматизації" url="/services" button_text="ДЕТАЛЬНІШЕ" />
                    <ServiceCard text="Виробництво дорожніх знаків" url="/services" button_text="ДЕТАЛЬНІШЕ" />
                </Slider>
            </div>

            <div
                className="aboutText"
                style={{
                    backgroundImage: `url(${isPhone ? AboutPhone : AboutBG})`,
                    backgroundPosition: 'right',
                    backgroundRepeat: 'no-repeat'
                }}
            >
                <div className="aboutContainer">
                    {aboutInfoData.map((item, index) => (
                        <div className="aboutInfoBlock" key={index}>
                            <span className="aboutInfoTitle">{item.number}</span>
                            <span className="aboutInfoText">{item.text}</span>
                        </div>
                    ))}
                </div>
                <div className={"aboutContent"}>
                    <p>ПРО КОМПАНІЮ</p>
                    <span>Сучасна команда, яка об'єднала спеціалістів вузького профілю та високої кваліфікації. Маємо необхідний досвід роботи - понад 8 років, технічні, тактичні та фізичні засоби для реалізації проектів.</span>
                    <button className="captionInfoButton" onClick={() => navigate("/services")}>
                        <label>ДІЗНАТИСЯ БІЛЬШЕ</label>
                        <svg width="31" height="14" viewBox="0 0 31 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M22.3534 11.4883C21.9321 11.8455 21.8801 12.4765 22.2372 12.8978C22.5943 13.3191 23.2253 13.3711 23.6466 13.014L29.8416 7.76294C30.0657 7.57294 30.195 7.29396 30.195 7.00011C30.195 6.70626 30.0657 6.42728 29.8416 6.23728L23.6466 0.986214C23.2253 0.629107 22.5943 0.681146 22.2372 1.10245C21.8801 1.52374 21.9321 2.15477 22.3534 2.51187L26.4582 5.99124L1 5.99124C0.447715 5.99124 0 6.43896 0 6.99124C0 7.54353 0.447715 7.99124 1 7.99124L26.4791 7.99124L22.3534 11.4883Z" fill="#F37E3C" />
                        </svg>
                    </button>
                </div>
            </div>
            <div className="aboutContainerPhone">
                {aboutInfoData.map((item, index) => (
                    <div className="aboutInfoBlockPhone" key={index}>
                        <span className="aboutInfoTitlePhone">{item.number}</span>
                        <span className="aboutInfoTextPhone">{item.text}</span>
                    </div>
                ))}
            </div>

            <div className="productionContainer">
                <span className={"productionContainerText"}>ВЛАСНЕ ВИРОБНИЦТВО</span>
                <Slider {...settings} className="service-slider productionContainerSlider">
                    <ServiceCard text="Повітропроводи, димоходи, аспіраційні системи" url="/catalog#Круглі повітропроводи" button_text="В КАТАЛОГ" />
                    <ServiceCard text="Кондиціонери для транспорту" url="/catalog#Кондиціонери для транспорту" button_text="В КАТАЛОГ" />
                    <ServiceCard text="Щити автоматизації" url="/catalog#Щити автоматизації" button_text="В КАТАЛОГ" />
                    <ServiceCard text="Дорожні знаки" url="/catalog#Дорожні знаки" button_text="В КАТАЛОГ" />
                </Slider>
                <div className={"productionContainerCardsList"}>
                    <ServiceCard text="Повітропроводи, димоходи, аспіраційні системи" url="/catalog#Круглі повітропроводи" button_text="В КАТАЛОГ" />
                    <ServiceCard text="Кондиціонери для транспорту" url="/catalog#Кондиціонери для транспорту" button_text="В КАТАЛОГ" />
                    <ServiceCard text="Щити автоматизації" url="/catalog#Щити автоматизації" button_text="В КАТАЛОГ" />
                    <ServiceCard text="Дорожні знаки" url="/catalog#Дорожні знаки" button_text="В КАТАЛОГ" />
                </div>
            </div>
            <div className="customerContainer">
                <span className={"productionContainerText"}>НАШІ ЗАМОВНИКИ</span>
                <img src={Customers} alt={"Замовники"} className={"customerContainerImage"}/>
            </div>
        </>
    );
}
