import React, { ReactNode, CSSProperties } from 'react';

interface FlexContainerProps {
    direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
    justify?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
    align?: 'stretch' | 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'none';
    wrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
    gap?: number;
    maxWidth?: number;
    padding?: number | string;
    margin?: number | string;
    children?: ReactNode;
    className?: string;
    style?: CSSProperties;
}

const FlexContainer: React.FC<FlexContainerProps> = ({
                                                         direction = 'row',
                                                         justify = 'flex-start',
                                                         align = 'center',
                                                         wrap = 'nowrap',
                                                         gap = 0,
                                                         padding,
                                                         margin,
                                                         children,
                                                         className,
                                                         style,
                                                         maxWidth
                                                     }) => {
    const flexContainerStyles: CSSProperties = {
        display: 'flex',
        maxWidth: maxWidth ? `${maxWidth}px` : '1440px',
        flexDirection: direction,
        justifyContent: justify,
        alignItems: align,
        flexWrap: wrap,
        gap,
        padding,
        margin,
        ...style,
    };

    return (
        <div className={className} style={flexContainerStyles}>
            {children}
        </div>
    );
};

export default FlexContainer;
