import instance from "../instanse";

export const getAllProducts = async (skip: number, limit: number, category: string | null) => {
    try {
        const response = await instance.get('/items', {
            headers: {
                Authorization: `${localStorage.getItem("token")}`,
            },
            params: {
                skip: skip,
                limit: limit,
                category: category
            },
        });
        return response.data
    } catch (error) {
        console.error('Error fetching products:', error);
    }
};

export const getAllCategories = async () => {
    try {
        const response = await instance.get('/items/category');
        return response.data
    } catch (error) {
        console.error('Error fetching products:', error);
    }
};

export const getProductById = async (id: string) => {
    try {
        const response = await instance.get(`/items/${id}`, {
            headers: {
                Authorization: `${localStorage.getItem("token")}`,
            }
        });
        return response.data
    } catch (error) {
        console.error('Error fetching product:', error);
    }
};

export const deleteProductById = async (id: number) => {
    try {
        const response = await instance.delete(`/items/${id}`, {
            headers: {
                Authorization: `${localStorage.getItem("token")}`,
            }
        });
        return response.data
    } catch (error) {
        console.error('Error deleting product:', error);
    }
};

export const deleteCategoryById = async (id: number) => {
    try {
        const response = await instance.delete(`/items/category/${id}`, {
            headers: {
                Authorization: `${localStorage.getItem("token")}`,
            }
        });
        return response.data
    } catch (error) {
        console.error('Error deleting product:', error);
    }
};

export const deleteSubcategoryById = async (id: number) => {
    try {
        const response = await instance.delete(`/items/subcategory/${id}`, {
            headers: {
                Authorization: `${localStorage.getItem("token")}`,
            }
        });
        return response.data
    } catch (error) {
        console.error('Error deleting product:', error);
    }
};

export const deleteItemImage = async (id: number, filename: string) => {
    try {
        const response = await instance.post(`/items/delete_image`,
            {
                itemId: id,
                fileName: filename,
            },
            {
            headers: {
                Authorization: `${localStorage.getItem("token")}`,
            }
        });
        return response.data
    } catch (error) {
        console.error('Error deleting image:', error);
    }
};