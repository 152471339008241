import React, {useState} from 'react';
import './Consult.css';
import ConsultImage from "../../Images/ConsultImage.png";
import ThreeIcons from "../../Utils/ThreeIcons/ThreeIcons";
import FlexContainer from "../../Utils/FlexContainer/FlexContainer";
import Text from "../../Utils/Text/Text";
import Button from "../../Utils/Button/Button";
import Header from "../../Images/HomePage/Header1.png";
import {createUserRequest} from "../../Api/userRequestsApi/userRequestsApi";

const Consult: React.FC = () => {
    const [disabled, setDisabled] = useState(false);
    const [textButton, setTextButton] = useState('ЗВ\'ЯЖІТЬСЯ ЗІ МНОЮ');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhone(event.target.value);
    };

    const handleSubmit = async () => {
        if (name.trim() !== '' || phone.trim() !== '') {
            await createUserRequest(name, phone);
            setTextButton("Вашу заявку оброблено!")
            setDisabled(true);
        }

    };
    return (
        <div
            className={"consultContainer"}
            style={{
                backgroundImage: `url(${ConsultImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
            }}
        >
            <div className={"consultContent"}>
                <span className={"consultTitle"}>ОТРИМАЙТЕ КОНСУЛЬТАЦІЮ</span>
                <input className={"consultInput"} placeholder={"Ім'я"} value={name} onChange={handleNameChange} />
                <input className={"consultInput"} placeholder={"Телефон"} value={phone} onChange={handlePhoneChange} />
                <div className={"consultBtns"}>
                    <button
                        className={"consultButton"}
                        onClick={handleSubmit}
                        disabled={disabled}
                    >
                        {textButton}
                    </button>
                    <ThreeIcons></ThreeIcons>
                </div>
            </div>
        </div>
    );
};

export default Consult;