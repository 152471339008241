import React, { useState } from 'react';
import instance from "../../../../Api/instanse";
import "./../AdminForms.css";
import {Category} from "../../../../Types";
interface EditProductModalProps {
    selectedCategory?: Category;
    onClose: () => void;
}

const CategoryForm: React.FC<EditProductModalProps> = ({ onClose, selectedCategory }) => {
    const initialCategory = {
        name: '',
    };

    const [category, setCategory] = useState(selectedCategory || initialCategory);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setCategory((prevCategory) => ({
            ...prevCategory,
            [name]: value,
        }));
    };


    const handleSaveProduct = async () => {
        if (selectedCategory) {
            try {
                await instance.put(
                    `/items/category`,
                    category,
                    {
                        headers: {
                            Authorization: `${localStorage.getItem("token")}`,
                        },
                    }
                );
                onClose();
            } catch (error) {
                console.error('Create/Update record error:', error);
            }
        } else {
            try {
                await instance.post(
                    `/items/category`,
                    category,
                    {
                        headers: {
                            Authorization: `${localStorage.getItem("token")}`,
                        },
                    }
                );
                onClose();
            } catch (error) {
                console.error('Create/Update record error:', error);
            }
        }
    };


    return (
        <div className="modal">
            <div className="modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <h2>Додати нову категорію</h2>

                <label>Назва:</label>
                <input type="text" name="name" value={category.name} onChange={handleInputChange} />

                <button onClick={handleSaveProduct}>{selectedCategory ? 'Оновити' : 'Створити'}</button>
            </div>
        </div>
    );
};

export default CategoryForm;
