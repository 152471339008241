import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {ProductOrder} from "../../Types";

interface CartState {
    items: ProductOrder[];
}

const initialState: CartState = {
    items: [],
};

function areProductsEqual(product1: ProductOrder, product2: ProductOrder): boolean {
    return (
        product1.id === product2.id &&
        product1.name === product2.name &&
        product1.width === product2.width &&
        product1.len === product2.len &&
        product1.thickness === product2.thickness &&
        product1.diameter === product2.diameter &&
        product1.diameter1 === product2.diameter1 &&
        product1.diameter2 === product2.diameter2 &&
        product1.angle === product2.angle &&
        product1.item_type === product2.item_type
    );
}

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addToCart(state, action: PayloadAction<ProductOrder>) {
            const existingProduct = state.items.find(item => areProductsEqual(item, action.payload));

            if (existingProduct) {
                existingProduct.quantity += 1;
            } else {
                state.items.push({ ...action.payload, quantity: 1 });
            }
        },
        removeFromCart(state, action: PayloadAction<ProductOrder>) {
            state.items = state.items.filter(item => !areProductsEqual(item, action.payload));
        },
    },
});

export const { addToCart, removeFromCart } = cartSlice.actions;

export default cartSlice.reducer;
