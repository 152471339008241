import FlexContainer from "../../Utils/FlexContainer/FlexContainer";
import Text from "../../Utils/Text/Text";
import React from "react";

import "./CatalogItemDesc.css";

interface ICatalogItemDescProps {
    description: string;
}

export const CatalogItemDesc: React.FC<ICatalogItemDescProps> = ({ description }) => {

    return (
        <div className={"orderDescWrapper"}>
            <FlexContainer>
                <Text className={"titleW2"}>Опис</Text>
            </FlexContainer>
            <div
                style={{
                    marginTop: 20,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    gap: 5,
                    color: "var(--secondary-dark-gry, #595A5A)",
                    fontFamily: "'Inter', sans-serif",
                    fontStyle: "normal",
                    fontWeight: 300,
                    width: "100%"
                }}
                dangerouslySetInnerHTML={{ __html: description }}
            />
        </div>
    );
}