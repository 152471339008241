import React, {FC} from "react";
import RequestTable from "../Tables/RequestTable/RequestTable";


export const AdminRequests: FC = () => {
    return (
    <>
        <div className={"adminPanelHeader"}>
            <span className={"adminCategoriesHeader"}>Запити на консультацію</span>
        </div>
        <div className={"adminPanelContent"}>
            <RequestTable></RequestTable>
        </div>
    </>
    );
};
