import FlexContainer from "../../Utils/FlexContainer/FlexContainer";
import Text from "../../Utils/Text/Text";
import Counter from "../../Utils/Counter/Counter";
import CustomSelect from "../../Utils/CustomSelect/CustomSelect";
import Button from "../../Utils/Button/Button";
import React, {useState} from "react";
import {parseStringToArray} from "../../Utils/utils";
import {useDispatch} from "react-redux";
import {addToCart} from "../../Store/Reducers/basketReducer";
import {Product, ProductOrder} from "../../Types";

import "./CatalogItemOrder.css";
import {setIsPriceRequestModalOpened, setSelectedProductName} from "../../Store/Reducers/pageReducer";

interface CatalogItemOrderProps{
    product: Product
}

const CatalogItemOrder: React.FC<CatalogItemOrderProps> = ({ product }) => {
    const dispatch = useDispatch();
    const [counter, setCounter] = useState(0);
    const [selectedOptions, setSelectedOptions] = useState({
        width: "",
        len: "",
        thickness: "",
        diameter: "",
        diameter1: "",
        diameter2: "",
        angle: "",
        item_type: ""
    });
    const handleAddToCart = () => {
        const order: ProductOrder = {
            id: product.id || 1,
            name: product.name,
            img_url: product.images[0],
            quantity: counter,
            ...(product.width !== '' && { width: selectedOptions.width }),
            ...(product.len !== '' && { len: selectedOptions.len }),
            ...(product.thickness !== '' && { thickness: selectedOptions.thickness }),
            ...(product.diameter !== '' && { diameter: selectedOptions.diameter }),
            ...(product.diameter1 !== '' && { diameter1: selectedOptions.diameter1 }),
            ...(product.diameter2 !== '' && { diameter2: selectedOptions.diameter2 }),
            ...(product.angle !== '' && { angle: selectedOptions.angle }),
            ...(product.item_type !== '' && { item_type: selectedOptions.item_type }),
        }
        dispatch(addToCart(order));
    };

    const handleCountChange = (newCount: number) => {
        setCounter(newCount);
    };

    const handleSelectChange = (key: string, value: string) => {
        setSelectedOptions((prevOptions) => ({
            ...prevOptions,
            [key]: value,
        }));
    };

    return (
        <div className={"orderWrapper"}>
            <div className={"orderHeader"}>
                {true ? (
                    <Text className={"subtitleW3"} color={"var(--primary-orange, #F37E3C)"}>В наявності</Text>
                ) : (
                    <Text className={"subtitleW3"} color={"#9A9A9A"}>Під замовлення</Text>
                )
                }
                <Counter onCountChange={handleCountChange} counter={counter}/>
            </div>

            <div className={"orderParams"}>
                {product.width &&
                    <CustomSelect
                        title={"Ширина"}
                        option={"розмір"}
                        options={parseStringToArray(product.width)}
                        onChange={(value) => handleSelectChange("width", value)}
                    />
                }
                {product.len &&
                    <CustomSelect
                        title={"Довжина"}
                        option={"розмір"}
                        options={parseStringToArray(product.len)}
                        onChange={(value) => handleSelectChange("len", value)}
                    />
                }
                {product.thickness &&
                  <CustomSelect
                    title={"Товщина"}
                    option={"розмір"}
                    options={parseStringToArray(product.thickness)}
                    onChange={(value) => handleSelectChange("thickness", value)}
                  />
                }
                {product.diameter &&
                  <CustomSelect
                    title={"Діаметр"}
                    option={"розмір"}
                    options={parseStringToArray(product.diameter)}
                    onChange={(value) => handleSelectChange("diameter", value)}
                  />
                }
                {product.diameter1 &&
                  <CustomSelect
                    title={"Діметр 1"}
                    option={"розмір"}
                    options={parseStringToArray(product.diameter1)}
                    onChange={(value) => handleSelectChange("diameter1", value)}
                  />
                }
                {product.diameter2 &&
                  <CustomSelect
                    title={"Діаметр 2"}
                    option={"розмір"}
                    options={parseStringToArray(product.diameter2)}
                    onChange={(value) => handleSelectChange("diameter2", value)}
                  />
                }
                {product.angle &&
                  <CustomSelect
                    title={"Кут"}
                    option={"розмір"}
                    options={parseStringToArray(product.angle)}
                    onChange={(value) => handleSelectChange("angle", value)}
                  />
                }
                {product.item_type &&
                  <CustomSelect
                    title={"Тип"}
                    option={"розмір"}
                    options={parseStringToArray(product.item_type)}
                    onChange={(value) => handleSelectChange("item_type", value)}
                  />
                }
            </div>
            <div className={"orderBtns"}>
                <button className={"orderBtn1"} onClick={handleAddToCart}>Зберегти</button>
                <button
                    className={"orderBtn2"}
                    onClick={() => {
                        dispatch(setSelectedProductName(product.name));
                        dispatch(setIsPriceRequestModalOpened(true));
                    }}
                >Запросити ціну</button>
            </div>
        </div>
    );
}
export default CatalogItemOrder;