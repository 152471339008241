import React from 'react';
import { NavLink } from 'react-router-dom';
import Text from "../../Utils/Text/Text";
import './Footer.css';
import LogoImage from "../../Images/Logos/Logo.svg"
// import InstagramIcon from "../../Images/InstagramIcon.svg"
import FacebookIcon from "../../Images/Icons/FacebookIcon.svg"
import LinkedInIcon from "../../Images/Icons/LinkedInIcon.svg"
import FlexContainer from "../../Utils/FlexContainer/FlexContainer";

const Footer: React.FC = () => {
    return (
        <>
            <div className={"footerWrapper"}>
                <div className={"footerLinks"}>
                    <NavLink to="/" end className="footer-nav-link">Головна</NavLink>
                    <NavLink to="/about" end className="footer-nav-link">Про компанію</NavLink>
                    <NavLink to="/services" end className="footer-nav-link">Наші послуги</NavLink>
                    <NavLink to="/catalog" end className="footer-nav-link">Каталог</NavLink>
                    <NavLink to="/contact" end className="footer-nav-link">Контакти</NavLink>
                </div>
                <div className={"footerInfoTabs"}>
                    <div className={"footerInfoTab"}>
                        <Text className={"titleW3"} color={"#595A5A"}>Електорна пошта:</Text>
                        <Text className={"bodyW3"} color={"#A9A9A9"}>trade@ov-k.com.ua</Text>
                    </div>
                    <div className={"footerInfoTab"}>
                        <Text className={"titleW3"} color={"#595A5A"}>Телефон:</Text>
                        <Text className={"bodyW3"} color={"#A9A9A9"}>+38 (097) 345-67-89</Text>
                    </div>
                    <div className={"footerInfoTab"}>
                        <Text className={"titleW3"} color={"#595A5A"}>Адреса:</Text>
                        <Text className={"bodyW3 mobileText"} color={"#A9A9A9"}>Україна, м. Кривий Ріг,<br/> вул. Цегельна, буд. 1</Text>
                        <Text className={"bodyW3 tabletText"} color={"#A9A9A9"}>Україна, м. Кривий Ріг, вул. Цегельна, буд. 1</Text>
                    </div>
                </div>
                <div className={"footerSocialMediaWrapper"}>
                    <div className={"footerInfoTabs"}>
                        <img src={LogoImage} alt='Error'/>
                    </div>
                    <div className={"footerSocialMedia"}>
                        <span>Ми в соц. мережах</span>
                        <div className={"footerSocialWrapper"}>
                            <a href={"https://www.linkedin.com"}>
                                <img src={LinkedInIcon} alt='Error'/>
                            </a>
                            <a href={"https://www.instagram.com"}>
                                {/*<img src={InstagramIcon} alt='Error'/>*/}
                                <img src={FacebookIcon} alt='Error'/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"authorsWrapper"}>
                <span className={"authorsText"}>© 2023 Всі права захищені</span>
                <span className={"authorsText"}>Дизайн сайту <u>Калінкіна Ірина</u></span>
            </div>
        </>
    );
};

export default Footer;