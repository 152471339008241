import React, { useState} from 'react';
import EditProductModal from "../Forms/ProductForm/ProductForm";
import ProductTable from "../Tables/ProductTable/ProductTable";
import {Product} from "../../../Types";


export const AdminItems: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<Product | undefined>(undefined);

    const handleCreateNewProduct = () => {
        setSelectedProduct(undefined);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleSelectProduct = (item: Product) => {
        setSelectedProduct(item);
        setIsModalOpen(true);
    }

    return (
        <>
            <div className={"adminPanelHeader"}>
                <span className={"adminCategoriesHeader"}>Товари</span>
                <button
                    className={"createButton"}
                    onClick={handleCreateNewProduct}
                >
                    Додати новий товар
                </button>
            </div>
            <section id="products">
                <ProductTable onSelect={handleSelectProduct}></ProductTable>
            </section>
            {isModalOpen &&
                <EditProductModal
                    item={selectedProduct}
                    onClose={handleCloseModal}
                />}
        </>
    );
};
