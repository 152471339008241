import React from 'react';
import "./About.css";
import HeaderBack from "../../Images/AboutPage/header-back.png";
import PageDisc from "../../Utils/PageDisc/PageDisc";
import Button from "../../Utils/Button/Button";
import ExpCard from "../../Utils/ExpCard/ExpCard";

import Card1 from "../../Images/AboutPage/Card1.png";
import Card2 from "../../Images/AboutPage/Card2.png";
import Card3 from "../../Images/AboutPage/Card3.png";
import Card4 from "../../Images/AboutPage/Card4.png";
import Certificate from "../../Images/AboutPage/Certificate.png";

import ServiceCard from "../../Utils/ServiceCard/ServiceCard";
import FlexContainer from "../../Utils/FlexContainer/FlexContainer";
import Text from "../../Utils/Text/Text";
import AdvantageCard from "../../Utils/AdvantageCard/AdvantageCard";
import { ReactComponent as NextArrow } from "../../Images/Icons/NextArrow.svg";
import { ReactComponent as PreviousArrow } from "../../Images/Icons/PreviousArrow.svg";
import RedirectButton from "../../Utils/RedirectButton/RedirectButton";
import Slider from "react-slick";
import {setIsCallRequestModalOpened} from "../../Store/Reducers/pageReducer";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

export function About() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const settings = {
        slidesToShow: 4,
        slidesToScroll: 2,
        infinite: true,
        centerMode: true,
        centerPadding: '40px',
        prevArrow: <PreviousArrow />,
        nextArrow: <NextArrow />,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    arrows: false,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    centerMode: true,
                    centerPadding: '40px'
                }
            },
            {
                breakpoint: 950,
                settings: {
                    arrows: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    centerMode: true,
                    centerPadding: '40px'
                }
            },
            {
                breakpoint: 700,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    centerMode: true,
                    centerPadding: '40px'
                }
            }
        ]
    };

    return (
        <>
            <div
                className={"aboutHeaderWrapper"}
                style={{
                    background: `linear-gradient(0deg, rgba(54, 54, 54, 0.70) 0%, rgba(54, 54, 54, 0.70) 100%),
                    url(${HeaderBack}) lightgray 50% / cover no-repeat`
                }}
            >
                <span className={"aboutHeaderTitle"}>ПРО КОМПАНІЮ</span>
                <span className={"aboutHeaderText"}>
                    Сучасна команда, яка об'єднала спеціалістів вузького профілю та високої кваліфікації. Маємо необхідний досвід роботи - понад 8 років, технічні, тактичні та фізичні засоби для реалізації проектів
                </span>
            </div>
            <PageDisc header={"Головна"} current_page={"Про компанію"}></PageDisc>
            <div className={"aboutTagWrapper"}>
                <div className={"aboutTagsBlock"}>
                    <span className={"aboutTag"}>8 років на ринку</span>
                    <span className={"aboutTag"}>150+ проектів</span>
                    <span className={"aboutTag"}>Висока якість та гарантія</span>
                    <span className={"aboutTag"}>Індивідуальний підхід</span>
                </div>
                <div className={"aboutExpBlock"}>
                    <span className={"aboutExpTitle"}>НАШ ДОСВІД</span>
                    <span className={"aboutExpText"}>Реалізуємо складні проекти, підбираємо і виготовляємо необхідне обладнання. Готуємо документацію  для контролюючих органів для здачи обʼєкту в експлуатацію.</span>
                    <div className={"aboutExpBtns"}>
                        <button className={"aboutExpBtn1"} onClick={() => navigate("/services")}>БІЛЬШЕ ПРО ПОСЛУГИ</button>
                        <button className={"aboutExpBtn2"} onClick={() => dispatch(setIsCallRequestModalOpened(true))}>ЗАМОВИТИ ДЗВІНОК</button>
                    </div>
                </div>
            </div>
            <div className={"aboutCardsBlock"}>
                <div className={"aboutCard"}>
                    <span className={"aboutCardNumber1"}>01</span>
                    <img className={"aboutCardImg"} src={Card1} alt={""}/>
                    <div className={"aboutCardDesc"}>
                        <span className={"aboutCardNumber2"}>01</span>
                        <span className={"aboutCardText"}>Необхідні допуски на виконання робіт</span>
                        <RedirectButton text="ДІЗНАТИСЯ БІЛЬШЕ" />
                    </div>

                </div>
                <div className={"aboutCard"}>
                    <span className={"aboutCardNumber1"}>02</span>
                    <img className={"aboutCardImg"} src={Card2} alt={""}/>
                    <div className={"aboutCardDesc"}>
                        <span className={"aboutCardNumber2"}>02</span>
                        <span className={"aboutCardText"}>Власне виробництво</span>
                        <RedirectButton text="ДІЗНАТИСЯ БІЛЬШЕ" />
                    </div>
                </div>
                <div className={"aboutCard"}>
                    <span className={"aboutCardNumber1"}>03</span>
                    <img className={"aboutCardImg"} src={Card3} alt={""}/>
                    <div className={"aboutCardDesc"}>
                        <span className={"aboutCardNumber2"}>03</span>
                        <span className={"aboutCardText"}>Сучасне програмне забезпечення</span>
                        <RedirectButton text="ДІЗНАТИСЯ БІЛЬШЕ" />
                    </div>
                </div>
                <div className={"aboutCard"}>
                    <span className={"aboutCardNumber1"}>04</span>
                    <img className={"aboutCardImg"} src={Card4} alt={""}/>
                    <div className={"aboutCardDesc"}>
                        <span className={"aboutCardNumber2"}>04</span>
                        <span className={"aboutCardText"}>Автоматизації інженерних систем</span>
                        <RedirectButton text="ДІЗНАТИСЯ БІЛЬШЕ" />
                    </div>
                </div>
            </div>
            <div className="productionContainer">
                <span className={"productionContainerText"}>ВЛАСНЕ ВИРОБНИЦТВО</span>
                <Slider {...settings} className="service-slider productionContainerSlider">
                    <ServiceCard text="Повітропроводи, димоходи, аспіраційні системи" url="/catalog#Круглі повітропроводи" button_text="В КАТАЛОГ" />
                    <ServiceCard text="Кондиціонери для транспорту" url="/catalog#Кондиціонери для транспорту" button_text="В КАТАЛОГ" />
                    <ServiceCard text="Щити автоматизації" url="/catalog#Щити автоматизації" button_text="В КАТАЛОГ" />
                    <ServiceCard text="Дорожні знаки" url="/catalog#Дорожні знаки" button_text="В КАТАЛОГ" />
                </Slider>
                <div className={"productionContainerCardsList"}>
                    <ServiceCard text="Повітропроводи, димоходи, аспіраційні системи" url="/catalog#Круглі повітропроводи" button_text="В КАТАЛОГ" />
                    <ServiceCard text="Кондиціонери для транспорту" url="/catalog#Кондиціонери для транспорту" button_text="В КАТАЛОГ" />
                    <ServiceCard text="Щити автоматизації" url="/catalog#Щити автоматизації" button_text="В КАТАЛОГ" />
                    <ServiceCard text="Дорожні знаки" url="/catalog#Дорожні знаки" button_text="В КАТАЛОГ" />
                </div>
            </div>
            <div className={"aboutServiceBlock"}>
                <div className={"aboutServiceContent"}>
                    <div className={"aboutServiceCard"}>
                        <div className={"line"}></div>
                        <div className={"aboutServiceCardTitle"}>Входимо в положення</div>
                        <div className={"aboutServiceCardText"}>Підбираємо найкращі рішення спираючись на ваші потреби та бюджет та входячи в становище</div>
                    </div>
                    <div className={"aboutServiceCard"}>
                        <div className={"line"}></div>
                        <div className={"aboutServiceCardTitle"}>Повний цикл послуг</div>
                        <div className={"aboutServiceCardText"}>Від діагностування до індивідуального вироблення обладнання</div>
                    </div>
                    <div className={"aboutServiceCard"}>
                        <div className={"line"}></div>
                        <div className={"aboutServiceCardTitle"}>Економимо час та нерви</div>
                        <div className={"aboutServiceCardText"}>З вами на всіх етапах роботи та оперативно вирішимо всі задачі і питання</div>
                    </div>
                    <div className={"aboutServiceCard"}>
                        <div className={"line"}></div>
                        <div className={"aboutServiceCardTitle"}>Обслуговуємо по завершенню</div>
                        <div className={"aboutServiceCardText"}>Сервісне та технічне обслуговування систем вентиляції, кондиціювання та опалення</div>
                    </div>
                </div>
            </div>
                {/*<FlexContainer*/}
                {/*    gap={250}*/}
                {/*    justify={"space-between"}*/}
                {/*    style={{*/}
                {/*        width: "100%",*/}
                {/*        background: `linear-gradient(0deg, rgba(54, 54, 54, 0.70) 0%, rgba(54, 54, 54, 0.70) 100%),*/}
                {/*                 url(${HeaderBack}) lightgray 50% / cover no-repeat`*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <Text className={"headLineW1"} color={'var(--primary-white, #FFF)'} styles={{marginLeft: 56}}>*/}
                {/*        Про компанію*/}
                {/*    </Text>*/}
                {/*    <Text*/}
                {/*        className={"titleW2"}*/}
                {/*        color={'var(--primary-white, #FFF)'}*/}
                {/*        maxWidth={644}*/}
                {/*        styles={{margin: "78px 56px 78px 0"}}*/}
                {/*    >*/}
                {/*        Сучасна команда, яка об'єднала спеціалістів вузького профілю та високої кваліфікації. Маємо*/}
                {/*        необхідний досвід роботи - понад 8 років, технічні, тактичні та фізичні засоби для реалізаці*/}
                {/*        ї проектів*/}
                {/*    </Text>*/}
                {/*</FlexContainer>*/}
                {/*<PageDisc header={"Головна"} current_page={"Про компанію"}></PageDisc>*/}

                {/*<FlexContainer direction={'column'} justify={'space-around'} gap={120} maxWidth={1328}>*/}
                {/*    <FlexContainer wrap={'wrap'} justify={'center'} gap={41} margin={'80px 0 120px 0'}>*/}
                {/*        <FlexContainer wrap={'wrap'} gap={40} maxWidth={644}>*/}
                {/*            <span className={"experience-tag titleW1"}>8 років<br/>на ринку</span>*/}
                {/*            <span className={"experience-tag titleW1"}>150+<br/> проектів</span>*/}
                {/*            <span className={"experience-tag titleW1"}>Висока якість<br/> та гарантія</span>*/}
                {/*            <span className={"experience-tag titleW1"}>Індивідуальний<br/>підхід</span>*/}
                {/*        </FlexContainer>*/}
                {/*        <FlexContainer direction={'column'} align={'flex-start'} gap={48}>*/}
                {/*            <Text className={"headLineW2"} color={"var(--primary-gray, #363636);"}>*/}
                {/*                НАШ ДОСВІД*/}
                {/*            </Text>*/}
                {/*            <Text className={'bodyW2'} maxWidth={638}>*/}
                {/*                Реалізуємо складні проекти, підбираємо і виготовляємо необхідне обладнання. Готуємо доку*/}
                {/*                ментацію  для контролюючих органів для здачи обʼєкту в експлуатацію.*/}
                {/*            </Text>*/}
                {/*            <div id={"exp-buttons"}>*/}
                {/*                <Button btn_type={"primary"}>БІЛЬШЕ ПРО ПОСЛУГИ</Button>*/}
                {/*                <Button btn_type={"basic"}>ЗАМОВИТИ ДЗВІНОК</Button>*/}
                {/*            </div>*/}
                {/*        </FlexContainer>*/}
                {/*    </FlexContainer>*/}
                {/*</FlexContainer>*/}

                {/*<FlexContainer wrap={'wrap'} gap={40} maxWidth={1328} justify={"center"}>*/}
                {/*    <ExpCard text={"Необхідні допуски на виконання робіт"} cardNo={"01"} img={Card1}/>*/}
                {/*    <ExpCard text={"Сучасне програмне забезпечення"} cardNo={"02"} img={Card2}/>*/}
                {/*    <ExpCard text={"Власне виробництво"} cardNo={"03"} img={Card3}/>*/}
                {/*    <ExpCard text={"Автоматизації інженерних систем"} cardNo={"04"} img={Card4}/>*/}
                {/*</FlexContainer>*/}

                {/*<FlexContainer maxWidth={1328}>*/}
                {/*    <FlexContainer direction={'column'} gap={80} margin={'110px 0 120px 0'}>*/}
                {/*        <span className={"market-text"}>ВЛАСНЕ ВИРОБНИЦТВО</span>*/}
                {/*        <FlexContainer gap={40}>*/}
                {/*            <ServiceCard text={"Повітропроводи, димоходи, аспіраційні системи"} url={"localhost"} button_text={"В КАТАЛОГ"}/>*/}
                {/*            <ServiceCard text={"Кондиціонери для транспорту"} url={"localhost"} button_text={"В КАТАЛОГ"}/>*/}
                {/*            <ServiceCard text={"Щити автоматизації"} url={"localhost"} button_text={"В КАТАЛОГ"}/>*/}
                {/*            <ServiceCard text={"Дорожні знаки"} url={"localhost"} button_text={"В КАТАЛОГ"}/>*/}
                {/*        </FlexContainer>*/}
                {/*    </FlexContainer>*/}
                {/*</FlexContainer>*/}

                {/*<FlexContainer maxWidth={1328}*/}
                {/*               gap={40}*/}
                {/*               padding={'88px 58px 52px 56px'}*/}
                {/*               style={{ background: 'var(--Very-light-gray, #F5F5F5)' }*/}
                {/*}>*/}
                {/*    <AdvantageCard*/}
                {/*        title={"Входимо\nв положення"}*/}
                {/*        text={"Підбираємо найкращі рішення спираючись на ваші потреби та бюджет\n та входячи в становище"}*/}
                {/*    />*/}
                {/*    <AdvantageCard*/}
                {/*        title={"Повний\nцикл послуг"}*/}
                {/*        text={"Від діагностування до індивідуального вироблення обладнання"}*/}
                {/*    />*/}
                {/*    <AdvantageCard*/}
                {/*        title={"Економимо\nчас та нерви"}*/}
                {/*        text={"З вами на всіх етапах роботи та оперативно вирішимо всі задачі\nі питання"}*/}
                {/*    />*/}
                {/*    <AdvantageCard*/}
                {/*        title={"Обслуговуємо\nпо завершенню"}*/}
                {/*        text={"Сервісне та технічне обслуговування систем вентиляції, кондиціювання та опалення"}*/}
                {/*    />*/}
                {/*</FlexContainer>*/}

                {/*<FlexContainer direction={"column"} gap={64} margin={'100px 0 70px 0'}>*/}
                {/*    <Text className={"headLineW2"}>*/}
                {/*        Наші відзнаки*/}
                {/*    </Text>*/}
                {/*    /!*TODO*!/*/}
                {/*    <FlexContainer gap={40}>*/}
                {/*        <img src={Certificate} alt={"Certificate"}/>*/}
                {/*        <img src={Certificate} alt={"Certificate"}/>*/}
                {/*        <img src={Certificate} alt={"Certificate"}/>*/}
                {/*        <img src={Certificate} alt={"Certificate"}/>*/}
                {/*    </FlexContainer>*/}
                {/*</FlexContainer>*/}
        </>
    );
}

