import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from '../Components/Navigation/Navigation';
import Footer from '../Components/Footer/Footer';
import Consult from '../Components/Cosultate/Consult';
import { Home } from '../Pages/HomePage/Home';
import { About } from '../Pages/AboutPage/About';
import { Service } from "../Pages/ServicePage/Service";
import {Catalog} from "../Pages/CatalogPage/Catalog";
import {CatalogItem} from "../Pages/CatalogItemPage/CatalogItem";
import {Contacts} from "../Pages/ContactsPage/Contacts";
import BasketPage from "../Pages/BasketPage/BasketPage";
import {useSelector} from "react-redux";
import {RootState} from "../Store/store";
import CallRequestModal from "../Components/CallRequestModal/CallRequestModal";
import {createPortal} from "react-dom";
import PriceRequestModal from "../Components/PriceRequestModal/PriceRequestModal";

const MainLayout = () => {
    const isPriceRequestModalOpened = useSelector((state: RootState) => state.page.isPriceRequestModalOpened);
    const isCallRequestModalOpened = useSelector((state: RootState) => state.page.isCallRequestModalOpened);

    return (
        <div style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative"
        }}>
            {isCallRequestModalOpened &&
                createPortal(<CallRequestModal/>, document.body)
            }
            {isPriceRequestModalOpened &&
                createPortal(<PriceRequestModal/>, document.body)
            }
            <Navbar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/services" element={<Service />} />
                <Route path="/catalog" element={<Catalog />} />
                <Route path="/catalog/:id" element={<CatalogItem />} />
                <Route path="/contact" element={<Contacts />} />
                <Route path="/basket" element={<BasketPage />} />
            </Routes>
            <Consult />
            <Footer />
        </div>
    );
};

export default MainLayout;
