import "./CatalogItem.css";
import PageDisc from "../../Utils/PageDisc/PageDisc";
import FlexContainer from "../../Utils/FlexContainer/FlexContainer";
import Text from "../../Utils/Text/Text";

import {CatalogItemDesc} from "../../Components/CatalogItemDesc/CatalogItemDesc";
import CatalogItemOrder from "../../Components/CatalogItemOrder/CatalogItemOrder";
import {CatalogItemDelivery} from "../../Components/CatalogItemDelivery/CatalogItemDelivery";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {getProductById} from "../../Api/ItemsApi/ItemsApi";
import {Product} from "../../Types";
import ImagesViewer from "../../Utils/ImagesViewer/ImagesViewer";

import "./CatalogItem.css";

export function CatalogItem() {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [product, setProduct] = useState<Product | null>(null)

    const fetchProductById = async () => {
        const data = await getProductById(String(id));
        setProduct(data.item);
    };

    useEffect(() => {
        setIsLoading(true);
        fetchProductById();
        setIsLoading(false);
    }, [id]);

    return (
        <>
            <PageDisc header={"Головна"} current_page={`Каталог | ${product?.subcategory_name}`}/>
            {product &&
              <div className={"itemWrapper"}>
                <span className={"itemWrapperTitle"}>{product.name}</span>
                <div className={"itemNavigation"}>
                    <ImagesViewer imagesList={product.images}/>
                    <CatalogItemOrder product={product}/>
                </div>
                <div className={"itemFooter"}>
                  <CatalogItemDesc description={product.description}/>
                  <CatalogItemDelivery/>
                </div>
              </div>
            }
        </>
    );
}

