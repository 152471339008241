import React from 'react';

interface TextProps {
    className: string;
    color?: string;
    children: React.ReactNode;
    maxWidth?: number;
    marginTop?: number;
    styles?: React.CSSProperties;
}

const Text: React.FC<TextProps> = ({ className, color = 'inherit', children, maxWidth, marginTop, styles }) => {
    const textStyles: React.CSSProperties = {
        color,
        maxWidth: maxWidth ? `${maxWidth}px` : 'none',
        marginTop: marginTop ? `${marginTop}px` : 0,
        ...styles,
    };

    return (
        <span className={className} style={textStyles}>
            {children}
        </span>
    );
};

export default Text;
