import PageDisc from "../../Utils/PageDisc/PageDisc";
import FlexContainer from "../../Utils/FlexContainer/FlexContainer";
import Text from "../../Utils/Text/Text";
import BasketItem from "../../Components/BasketItem/BasketItem";
import BasketOrderInfo from "../../Components/BasketOrderInfo/BasketOrderInfo";
import {useSelector} from "react-redux";
import {RootState} from "../../Store/store";
import Button from "../../Utils/Button/Button";
import {useNavigate} from "react-router-dom";

import "./BasketPage.css";

const BasketPage = () => {
    const navigate = useNavigate();
    const items = useSelector((state: RootState) => state.cart.items);

    return (
        <>
            <PageDisc header={"Головна"} current_page={"Кошик"}/>
            {items.length !== 0 ? (
                <div className={"basketWrapper"}>
                    <span className={"basketTitle"}>КОШИК</span>
                    <div className={"basketContent"}>
                        <div className={"basketBlocks"}>
                            {items.map((item) => (
                                <BasketItem key={item.id} item={item} />
                            ))}
                        </div>
                        <BasketOrderInfo/>
                    </div>
                </div>
            ): (
                <div className={"emptyBasketWrapper"}>
                    <div className={"emptyBasketContent"}>
                        <span className={"emptyBasketTitle"}>Ваш кошик порожній.</span>
                        <span className={"emptyBasketText"}>Подивіться наш каталог :)</span>
                        <div className={"emptyBasketBtns"}>
                            <button className={"emptyBasketBtn1"} onClick={() => navigate("/catalog")}>В КАТАЛОГ</button>
                            <button className={"emptyBasketBtn2"} onClick={() => navigate("/")}>НА ГОЛОВНУ</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default BasketPage