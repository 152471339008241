import { useSelector } from 'react-redux';
import { RootState } from '../../Store/store';
import "./ShoppingCart.css"
import {ReactComponent as Basket} from "../../Images/Icons/Basket.svg";
import {useNavigate} from "react-router-dom";

const ShoppingCart = () => {
    const navigate = useNavigate();
    const itemCount = useSelector((state: RootState) => state.cart.items.length);


    return (
        <div className="shopping-cart">
            <div className="cart-icon">
                { itemCount !== 0 && <div className="item-count-badge">{ itemCount }</div>}
                <Basket style={{cursor: "pointer"}} onClick={() => navigate("/basket")}/>
            </div>
        </div>
    );
};

export default ShoppingCart;
