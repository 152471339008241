import React, {FC, useEffect, useState} from "react";
import "./AdminSubcategories.css";
import {getAllSubcategories} from "../../../Api/userRequestsApi/userRequestsApi";
import SubcategoryTable from "../Tables/SubcategoryTable/SubcategoryTable";
import SubcategoryForm from "../Forms/SubCategoryForm/SubcategoryForm";
import {Category, Subcategory} from "../../../Types";

export const AdminSubcategories: FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedSubcategory, setSelectedSubcategory] = useState<Subcategory | undefined>(undefined);
    const [subcategories, setSubcategories] = useState<Subcategory[]>([]);

    const fetchSubcategories = async () => {
        const data = await getAllSubcategories();
        setSubcategories(data.subcategories);
    };

    useEffect(() => {
        fetchSubcategories();
    }, []);

    const handleClose = () => {
        fetchSubcategories();
        setIsModalOpen(false)
    }

    const handleCreateNewCategory = () => {
        setSelectedSubcategory(undefined);
        setIsModalOpen(true);
    };


    const handleSelectCategory= (subcategory: Subcategory) => {
        setSelectedSubcategory(subcategory);
        setIsModalOpen(true);
    }

    return (
        <>
            <div className={"adminPanelHeader"}>
                <span className={"adminSubcategoriesHeader"}>Підкатегорії</span>
                <button className={"createButton"} onClick={handleCreateNewCategory}>Створити підкатегорію</button>
            </div>
            <section id="products">
                <SubcategoryTable subcategories={subcategories} fetchSubcategories={fetchSubcategories} onSelect={handleSelectCategory}></SubcategoryTable>
            </section>
            {isModalOpen && <SubcategoryForm onClose={handleClose} selectedSubcategory={selectedSubcategory}/>}
        </>
    );
};
