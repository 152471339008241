import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { RootState } from "../../Store/store";
import "./PriceRequestModal.css";
import {createPriceRequest} from "../../Api/userRequestsApi/userRequestsApi";
import {setIsPriceRequestModalOpened} from "../../Store/Reducers/pageReducer";

const PriceRequestModal = () => {
    const dispatch = useDispatch();

    const isPriceRequestModalOpened = useSelector(
        (state: RootState) => state.page.isPriceRequestModalOpened
    );

    const selectedProductName = useSelector(
        (state: RootState) => state.page.selectedProductName
    );


    const [disabled, setDisabled] = useState(false);
    const [textButton, setTextButton] = useState('ЗВ\'ЯЖІТЬСЯ ЗІ МНОЮ');
    const [username, setUsername] = useState('');
    const [contactInfo, setContactInfo] = useState('');
    const [extraInfo, setExtraInfo] = useState('');

    const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(event.target.value);
    };

    const handleContactInfoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContactInfo(event.target.value);
    };

    const handleExtraInfoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setExtraInfo(event.target.value);
    };

    const handleSubmit = async () => {
        if (username.trim() !== '' || contactInfo.trim() !== '' || extraInfo.trim() !== '') {
            await createPriceRequest({username: username, contactInfo: contactInfo, extraInfo: extraInfo, items: selectedProductName});
            setTextButton("Замовлення відправлено")
            setDisabled(true);
        }
    };

    useEffect(() => {
        if (isPriceRequestModalOpened) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }

        return () => {
            document.body.style.overflow = "";
        };
    }, [isPriceRequestModalOpened]);

    return (
        <div className="callRequestModalWrapper">
            <div className={"callRequestModalContent"}>
                <span className={"callRequestModalTitle"}>ЗАПРОСИТИ ЦІНУ</span>
                <input className={"consultInput"} placeholder={"Ім'я"} value={username} onChange={handleUsernameChange} />
                <input className={"consultInput"} placeholder={"Телефон або пошта"} value={contactInfo} onChange={handleContactInfoChange} />
                <input className={"consultInput"} placeholder={"Додаткова інформація"} value={extraInfo} onChange={handleExtraInfoChange} />
                <button
                    className={"callRequestModalBtn"}
                    onClick={handleSubmit}
                    disabled={disabled}
                >
                    {textButton}
                </button>
                <button
                    className={"callRequestModalCloseBtn"}
                    onClick={() => dispatch(setIsPriceRequestModalOpened(false))}>
                    <svg
                        fill="#fff"
                        width="30px"
                        height="30px"
                        viewBox="0 0 24 24"
                        id="cross"
                        data-name="Line Color"
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon line-color"
                    >
                        <line
                            id="primary"
                            x1={19}
                            y1={19}
                            x2={5}
                            y2={5}
                            style={{
                                fill: "none",
                                stroke: "rgb(255, 255, 255)",
                                strokeLinecap: "round",
                                strokeLinejoin: "round",
                                strokeWidth: 2,
                            }}
                        />
                        <line
                            id="primary-2"
                            data-name="primary"
                            x1={19}
                            y1={5}
                            x2={5}
                            y2={19}
                            style={{
                                fill: "none",
                                stroke: "rgb(255, 255, 255)",
                                strokeLinecap: "round",
                                strokeLinejoin: "round",
                                strokeWidth: 2,
                            }}
                        />
                    </svg>
                </button>
            </div>
        </div>
    );
};

export default PriceRequestModal;
