import React from 'react';
import PageDisc from "../../Utils/PageDisc/PageDisc";
import contactImage from "../../Images/ContactsPage/map.png";
import FlexContainer from "../../Utils/FlexContainer/FlexContainer";
import Text from "../../Utils/Text/Text";
import LinkedInIcon from "../../Images/Icons/LinkedInIcon.svg";
import FacebookIcon from "../../Images/Icons/FacebookIcon.svg";

import "./Contacts.css";

export function Contacts() {
    return (
        <>
            <PageDisc header={"Головна"} current_page={"Контакти"}></PageDisc>
            <div className={"contactHeaderWrapper"}>
                <div className={"contactHeaderContent"}>
                    <span className={"contactHeaderTitle"}>КОНТАКТИ</span>
                    <div className={"contactCardWrapper"}>
                        <div className={"contactCard"}>
                            <span className={"contactCardTitle"}>Електронна пошта:</span>
                            <span className={"contactCardValue"}>trade@ov-k.com.ua</span>
                        </div>
                        <div className={"contactCard"}>
                            <span className={"contactCardTitle"}>Телефон:</span>
                            <span className={"contactCardValue"}>+38 (097) 345-67-89</span>
                        </div>
                        <div className={"contactCard"}>
                            <span className={"contactCardTitle"}>Адреса:</span>
                            <span className={"contactCardValue"}>Україна, м. Кривий Ріг, вул. Цегельна, буд. 1</span>
                        </div>
                        <div className={"contactCard"}>
                            <span className={"contactCardTitle"}>Ми в соцмережах</span>
                            <div className={"contactCardIcons"}>
                                <a href={"https://www.linkedin.com"}>
                                    <img src={LinkedInIcon} alt='Error'/>
                                </a>
                                <a href={"https://www.facebook.com"}>
                                    <img src={FacebookIcon} alt='Error'/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1456.9797788211006!2d33.43027376495047!3d47.95354585203291!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40db21775da371ef%3A0xa7cc46bbfeb8d40f!2z0J7Qki3QmtCe0JzQn9Cb0JXQmtCiINCi0J7Qkg!5e0!3m2!1suk!2sua!4v1730058278798!5m2!1suk!2sua" width="100%" height="450" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </>
    );
}
