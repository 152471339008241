import React from 'react';
import "./Service.css";
import FlexContainer from "../../Utils/FlexContainer/FlexContainer";
import HeaderBack from "../../Images/AboutPage/header-back.png";
import Text from "../../Utils/Text/Text";
import PageDisc from "../../Utils/PageDisc/PageDisc";
import Button from "../../Utils/Button/Button";
import ServiceCard from "../../Utils/ServiceCard/ServiceCard";
import ServiceImg from "../../Images/ServicePage/Service.png";
import Service1Img from "../../Images/ServicePage/Service1.png";
import { ReactComponent as NextArrow } from "../../Images/Icons/NextArrow.svg";
import { ReactComponent as PreviousArrow } from "../../Images/Icons/PreviousArrow.svg";
import Slider from "react-slick";
import {setIsCallRequestModalOpened} from "../../Store/Reducers/pageReducer";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

export function Service() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const settings = {
        slidesToShow: 4,
        slidesToScroll: 2,
        infinite: true,
        centerMode: true,
        centerPadding: '40px',
        prevArrow: <PreviousArrow />,
        nextArrow: <NextArrow />,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    arrows: false,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    centerMode: true,
                    centerPadding: '40px'
                }
            },
            {
                breakpoint: 950,
                settings: {
                    arrows: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    centerMode: true,
                    centerPadding: '40px'
                }
            },
            {
                breakpoint: 700,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    centerMode: true,
                    centerPadding: '40px'
                }
            }
        ]
    };

    return (
        <>
            <div
                className={"serviceHeaderWrapper"}
                style={{
                    background: `linear-gradient(0deg, rgba(54, 54, 54, 0.70) 0%, rgba(54, 54, 54, 0.70) 100%),
                    url(${HeaderBack}) lightgray 50% / cover no-repeat`
                }}
            >
                <span className={"serviceHeaderTitle"}>ПОСЛУГИ</span>
                <div className={"serviceHeaderDesc"}>
                    <span className={"serviceHeaderText"}>
                        Реалізація спеціалізованих робіт з будівництва будівель та споруд. Устаткування усіх видів комунікацій. Виробництво та монтаж.
                    </span>
                    <div className={"serviceHeaderBtnsWrapper"}>
                        <button className={"serviceHeaderBtn1"} onClick={() => navigate("/about")}>ПРО КОМПАНІЮ</button>
                        <button className={"serviceHeaderBtn2"} onClick={() => dispatch(setIsCallRequestModalOpened(true))}>ЗАМОВИТИ ДЗВІНОК</button>
                    </div>
                </div>
            </div>
            <PageDisc header={"Головна"} current_page={"Послуги"}></PageDisc>
            <div className="productionContainer">
                <Slider {...settings} className="service-slider productionContainerSlider">
                    <ServiceCard text="Зведення будинків та споруд" url="/" button_text="ДЕТАЛЬНІШЕ" />
                    <ServiceCard text="Постачання, монтаж та підбір обладнання" url="/" button_text="ДЕТАЛЬНІШЕ" />
                    <ServiceCard text="Супровід в підготовці дозвільної документації" url="/" button_text="ДЕТАЛЬНІШЕ" />
                    <ServiceCard text="Сервісне та технічне обслуговування інженерних мереж" url="/" button_text="ДЕТАЛЬНІШЕ" />
                    <ServiceCard text="Іноваційний відеонагляд" url="/" button_text="ДЕТАЛЬНІШЕ" />
                </Slider>
            </div>
            <div className={"serviceCardsDescTablet"}>
                <img src={ServiceImg} alt={"Service"} className={"serviceCardsDescImg"}/>
                <div className={"serviceDescBlock"}>
                        <span className={"serviceDescText"}>
                            Допомога із введенням обʼєктів в експлуатацію
                        </span>
                    <span className={"serviceDescBtn"}>
                            ДІЗНАТИСЯ БІЛЬШЕ
                        </span>
                </div>
            </div>
            <div className={"serviceCardsContainer"}>
                <div className={"serviceCardsHorizontal"}>
                    <ServiceCard text={"Зведення будинків та споруд"} url={"/"} button_text={"ДЕТАЛЬНІШЕ"} />
                    <ServiceCard text={"Постачання, монтаж та підбір обладнання"} url={"/"} button_text={"ДЕТАЛЬНІШЕ"} />
                    <ServiceCard text={"Супровід в підготовці дозвільної документації"} url={"/"} button_text={"ДЕТАЛЬНІШЕ"} />
                    <ServiceCard text={"Сервісне та технічне обслуговування інженерних мереж"} url={"/"} button_text={"ДЕТАЛЬНІШЕ"} />
                </div>
                <div className={"serviceCardsDesc"}>
                    <img src={ServiceImg} alt={"Service"}/>
                    <div className={"serviceDescBlock"}>
                        <span className={"serviceDescText"}>
                            Допомога із введенням обʼєктів в експлуатацію
                        </span>
                        <span className={"serviceDescBtn"}>
                            ДІЗНАТИСЯ БІЛЬШЕ
                        </span>
                    </div>
                    <div>
                        <ServiceCard text={"Іноваційний відеонагляд"} url={"/"} button_text={"ДЕТАЛЬНІШЕ"} />
                    </div>
                </div>
            </div>
            <div
                className={"serviceAboutWrapper"}
                style={{
                    background: `url(${Service1Img}) lightgray 50% / cover no-repeat`
                }}>
                <div className={"serviceAboutBlock"}>
                    <div className={"serviceAboutTitle"}>ПРО ВИРОБНИЦТВО</div>
                    <div className={"serviceAboutText"}>
                        Наша компанія спеціалізується на комплексних рішеннях для будівництва та обслуговування будинків
                        і споруд, зокрема, постачанні, монтажі й підборі обладнання. Ми забезпечуємо повний супровід
                        проекту — від допомоги в підготовці дозвільної документації до професійного технічного та
                        сервісного обслуговування інженерних мереж.<br/><br/> Завдяки інноваційному відеонагляду наші клієнти
                        можуть бути впевнені в надійності та безпеці своїх об’єктів. Наша команда професіоналів
                        завжди готова забезпечити індивідуальний підхід і високий рівень обслуговування для кожного
                        проекту.
                    </div>
                    <button className={"serviceAboutBtn"}>ПЕРЕЙТИ В КАТАЛОГ</button>
                </div>
            </div>
            <div className="productionContainer">
                <Slider {...settings} className="service-slider productionContainerSlider">
                    <ServiceCard text="Повітропроводи, димоходи, аспіраційні системи" url="/catalog#Круглі повітропроводи" button_text="В КАТАЛОГ" />
                    <ServiceCard text="Кондиціонери для транспорту" url="/catalog#Кондиціонери для транспорту" button_text="В КАТАЛОГ" />
                    <ServiceCard text="Щити автоматизації" url="/catalog#Щити автоматизації" button_text="В КАТАЛОГ" />
                    <ServiceCard text="Дорожні знаки" url="/catalog#Дорожні знаки" button_text="В КАТАЛОГ" />
                </Slider>
                <div className={"productionContainerCardsList"}>
                    <ServiceCard text="Повітропроводи, димоходи, аспіраційні системи" url="/catalog#Круглі повітропроводи" button_text="В КАТАЛОГ" />
                    <ServiceCard text="Кондиціонери для транспорту" url="/catalog#Кондиціонери для транспорту" button_text="В КАТАЛОГ" />
                    <ServiceCard text="Щити автоматизації" url="/catalog#Щити автоматизації" button_text="В КАТАЛОГ" />
                    <ServiceCard text="Дорожні знаки" url="/catalog#Дорожні знаки" button_text="В КАТАЛОГ" />
                </div>
            </div>
        </>
    );
}

