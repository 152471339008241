import React, { useState } from 'react';
import FlexContainer from "../FlexContainer/FlexContainer";
import Text from "../Text/Text";

import {ReactComponent as Minus} from "../../Images/Icons/Minus.svg";
import {ReactComponent as Plus} from "../../Images/Icons/Plus.svg";

interface CounterProps {
    counter: number;
    onCountChange: (count: number) => void;
}

const Counter: React.FC<CounterProps> = ({ counter, onCountChange }) => {
    const [count, setCount] = useState(counter);

    const increment = () => {
        setCount(count + 1);
        onCountChange(count + 1);
    };

    const decrement = () => {
        if (count > 0) {
            setCount(count - 1);
            onCountChange(count - 1);
        }
    };

    const buttonStyle = {
        cursor: 'pointer'
    };

    return (
        <FlexContainer align={"none"}>
            <Minus onClick={decrement} style={buttonStyle}></Minus>
            <Text className={"titleW2"}>{count}</Text>
            <Plus onClick={increment} style={buttonStyle}></Plus>
        </FlexContainer>
    );
};

export default Counter;
