import "./DragArea.css";
import {FC, useRef, useState} from "react";


interface DragAreaProps {
    onAdd: (files: any) => void;
}

export const DragArea: FC<DragAreaProps> = ({ onAdd }) => {
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const handleDragOver = (e: any) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = (e: any) => {
        e.preventDefault();
        setIsDragging(false);
        const files = e.dataTransfer.files;
        onAdd(files);
    };

    const handleFileChange = (e: any) => {
        const files = e.target.files;
        onAdd(files);
    };

    const handleAreaClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <div className="dragArea"
             onDragOver={handleDragOver}
             onDragLeave={handleDragLeave}
             onDrop={handleDrop}
             onClick={handleAreaClick}
        >
            <input
                type="file"
                ref={fileInputRef}
                multiple
                onChange={handleFileChange}
                style={{ display: 'none' }}
            />
            Перетягніть файли сюди або натисніть, щоб обрати
        </div>
    );
}