import React, {useState} from 'react';
import {Route, Routes, useNavigate} from "react-router-dom";
import "./AdminLayout.css";
import AdminNavbar from "../../Components/Admin/AdminNavbar/AdminNavbar";
import instance from "../../Api/instanse";
import {AdminRequests} from "../../Components/Admin/AdminRequests/AdminRequests";
import {AdminCategories} from "../../Components/Admin/AdminCategories/AdminCategories";
import {AdminItems} from "../../Components/Admin/AdminItems/AdminItems";
import {AdminSubcategories} from "../../Components/Admin/AdminSubcategories/AdminSubcategories";
import {AdminPriceRequests} from "../../Components/Admin/AdminPriceRequests/AdminRequests";
import {AdminOrders} from "../../Components/Admin/AdminOrders/AdminOrders";

const AdminLayout = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [token, setToken] = useState<string | null>(localStorage.getItem('token') || null);
    const navigate = useNavigate();
    const handleLogin = async () => {
        try {
            const response = await instance.post('/auth/login', { username, password });
            const newToken = response.data.accessToken;
            localStorage.setItem('token', newToken)
            setToken(newToken);
            navigate("/admin/products");
        } catch (error) {
            console.error('Login error:', error);
        }
    };

    const handleLogout = () => {
        setToken(null);
        localStorage.removeItem('token');
    };

    return (
        <>
            {!token ? (
                <div>
                    <h2>Login</h2>
                    <input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
                    <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    <button onClick={handleLogin}>Log In</button>
                </div>
            ) : (
            <div className={"adminLayout"}>
                <AdminNavbar />
                <div className={"adminContainer"}>
                    <Routes>
                        <Route path="/categories" element={<AdminCategories />} />
                        <Route path="/subcategories" element={<AdminSubcategories />} />
                        <Route path="/products" element={<AdminItems />} />
                        <Route path="/requests" element={<AdminRequests />} />
                        <Route path="/pricerequests" element={<AdminPriceRequests />} />
                        <Route path="/orders" element={<AdminOrders />} />
                    </Routes>
                </div>
            </div>
            )}
        </>
    );
};

export default AdminLayout;
