import { FC, useState } from "react";
import { useSwipeable } from "react-swipeable";
import "./ImagesViewer.css";
import { useMedia } from "use-media";
import arrow from "../../Images/arrow.png";

interface IImagesViewerProps {
    imagesList: string[];
}

const ImagesViewer: FC<IImagesViewerProps> = ({ imagesList }) => {
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [startIndex, setStartIndex] = useState(0);
    const isPhone = useMedia("(max-width: 1000px)");

    const visibleImages = imagesList.slice(startIndex, startIndex + 3);

    const handleImageClick = (index: number) => {
        setSelectedImageIndex(index + startIndex);
    };

    const handleNext = () => {
        if (startIndex + 3 < imagesList.length) {
            setStartIndex(prev => prev + 1);
        }
    };

    const handlePrevious = () => {
        if (startIndex > 0) {
            setStartIndex(prev => prev - 1);
        }
    };

    // Обработчики свайпов
    const swipeHandlers = useSwipeable({
        onSwipedLeft: isPhone ? handleNext : undefined,
        onSwipedRight: isPhone ? handlePrevious : undefined,
        onSwipedUp: !isPhone ? handleNext : undefined,
        onSwipedDown: !isPhone ? handlePrevious : undefined,
    });

    return (
        <div className="imageViewerWrapper" {...swipeHandlers}>
            {imagesList.length === 1 ? (
                <div className="imagePreviewBlock">
                    <img src={imagesList[0]} alt="Ошибка" />
                </div>
            ) : (
                <>
                    <div className="imagesPreviewBlock">
                        {imagesList.length > 3 && isPhone && (
                            <button
                                className="imagesPreviewLeft"
                                onClick={handlePrevious}
                                disabled={startIndex === 0}
                            >
                                <img style={{ width: 15, height: 15, rotate: "180deg" }} src={arrow} alt="<" />
                            </button>
                        )}
                        {imagesList.length > 3 && isPhone && (
                            <button
                                className="imagesPreviewRight"
                                onClick={handleNext}
                                disabled={startIndex + 3 >= imagesList.length}
                            >
                                <img style={{ width: 15, height: 15 }} src={arrow} alt=">" />
                            </button>
                        )}
                        {imagesList.length > 3 && !isPhone && (
                            <button
                                className="imagesPreviewTop"
                                onClick={handlePrevious}
                                disabled={startIndex === 0}
                            >
                                <img style={{ width: 15, height: 15, rotate: "-90deg" }} src={arrow} alt="^" />
                            </button>
                        )}
                        {imagesList.length > 3 && !isPhone && (
                            <button
                                className="imagesPreviewBottom"
                                onClick={handleNext}
                                disabled={startIndex + 3 >= imagesList.length}
                            >
                                <img style={{ width: 15, height: 15, rotate: "90deg" }} src={arrow} alt="v" />
                            </button>
                        )}
                        {visibleImages.map((image, index) => (
                            <img
                                className="previewImages"
                                key={index}
                                src={image}
                                alt="Ошибка"
                                style={{
                                    flex: `${imagesList.length === 2 ? "1 1 calc(50% - 10px" : "1 1 calc(33.33% - 10px"}`,
                                    maxWidth: `${imagesList.length === 2 ? "calc(50% - 10px)" : "calc(33.33% - 10px)"}`
                                }}
                                onClick={() => handleImageClick(index)}
                            />
                        ))}
                    </div>
                    <div className="imagePreviewBlock">
                        <img src={imagesList[selectedImageIndex]} alt="Ошибка" />
                    </div>
                </>
            )}
        </div>
    );
};

export default ImagesViewer;
