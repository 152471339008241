import React, { useState, useEffect } from 'react';
import instance from "../../../../Api/instanse";
import "./../AdminForms.css";
import {Category, Subcategory} from "../../../../Types";
import {getAllCategories} from "../../../../Api/userRequestsApi/userRequestsApi";

interface EditProductModalProps {
    selectedSubcategory?: Subcategory;
    onClose: () => void;
}

const SubcategoryForm: React.FC<EditProductModalProps> = ({ onClose, selectedSubcategory }) => {
    const initialSubcategory = {
        name: '',
        category_id: '',
    };

    const [subcategory, setSubcategory] = useState(selectedSubcategory || initialSubcategory);
    const [categories, setCategories] = useState<Category[]>([]);

    useEffect(() => {
        const fetchCategories = async () => {
            const data = await getAllCategories();
            setCategories(data.categories);
        };
        fetchCategories();
    }, []);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setSubcategory((prevSubcategory) => ({
            ...prevSubcategory,
            [name]: value,
        }));
    };

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = event.target;
        setSubcategory((prevSubcategory) => ({
            ...prevSubcategory,
            [name]: value,
        }));
    };

    const handleSaveProduct = async () => {
        if (selectedSubcategory) {
            await instance.put(
                `/items/subcategory`,
                subcategory,
                {
                    headers: {
                        Authorization: `${localStorage.getItem("token")}`,
                    },
                }
            );
            onClose();
        } else {
            try {
                await instance.post(
                    `/items/subcategory`,
                    subcategory,
                    {
                        headers: {
                            Authorization: `${localStorage.getItem("token")}`,
                        },
                    }
                );
                onClose();
            } catch (error) {
                console.error('Create/Update subcategory error:', error);
            }
        }
    };

    return (
        <div className="modal">
            <div className="modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <h2>Додати нову підкатегорію</h2>

                <label>Назва:</label>
                <input type="text" name="name" value={subcategory.name} onChange={handleInputChange} />

                <label>Категорія:</label>
                <select name="category_id" value={subcategory.category_id} onChange={handleSelectChange}>
                    <option value="">Выберите категорию</option>
                    {categories.map(category => (
                        <option key={category.id} value={category.id}>{category.name}</option>
                    ))}
                </select>

                <button onClick={handleSaveProduct}>{selectedSubcategory ? 'Оновити' : 'Створити'}</button>
            </div>
        </div>
    );
};

export default SubcategoryForm;
