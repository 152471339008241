import Text from "../../Utils/Text/Text";

import "./CatalogItemDelivery.css"

export function CatalogItemDelivery() {

    return (
        <div className={"orderDeliveryWrapper"}>
            <Text className={"titleW2"} color={"#363636"}>Доставка</Text>
            <Text className={"bodyW3"} color={"#363636"} marginTop={16}>Доставку здійснюємо службами:</Text>
            <div className={"orderDeliveryTools"}>
                <div className={"orderDeliveryTool"}>
                    <span>Нова Пошта</span>
                </div>
                <div className={"orderDeliveryTool"}>
                    <span>Delivery</span>
                </div>
                <div className={"orderDeliveryTool"}>
                    <span>Sad Delivery</span>
                </div>
            </div>
            <span className={"orderDeliveryFooter"}>
                Оплата доставки рахується окремо за тарифами служб.
            </span>
        </div>
    );
}