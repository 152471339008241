import {Subcategory} from "../../../../Types";
import './SubcategoryTable.css';
import React from "react";
import {deleteSubcategoryById} from "../../../../Api/ItemsApi/ItemsApi";

interface Props {
    fetchSubcategories: any;
    onSelect: (subcategory: Subcategory  ) => void;
    subcategories: Subcategory[];
}

const SubcategoryTable: React.FC<Props> = ({ subcategories, fetchSubcategories, onSelect }) => {

    const handleDelete = async (id: number) => {
        await deleteSubcategoryById(id);
        await fetchSubcategories();
    }

    const handleSelect = async (id: number) => {
        const subcategoryToSelect = subcategories.find((subcategory) => subcategory.id == id);
        if (subcategoryToSelect) {
            onSelect(subcategoryToSelect);
        }
    }

    return (
        <div className="table-container">
            <table>
                <thead>
                    <tr>
                        <th>№</th>
                        <th>Назва</th>
                        <th>Категорія</th>
                        <th>Дії</th>
                    </tr>
                </thead>
                <tbody>
                    {subcategories.map((subcategory, index) => (
                        <tr key={subcategory.id}>
                            <td>{index + 1}</td>
                            <td>{subcategory.name}</td>
                            <td>{subcategory.category_name}</td>
                            <td style={{ position: 'relative' }}>
                                <div>
                                    <button onClick={() => handleDelete(subcategory.id)}>Видалити</button>
                                    <button onClick={() => handleSelect(subcategory.id)}>Редагувати</button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default SubcategoryTable;
