import { Routes, Route } from 'react-router-dom';
import React from "react";
import MainLayout from './Layouts/MainLayout';
import AdminLayout from './Layouts/AdminLayout/AdminLayout';

function Router() {
    return (
        <Routes>
            <Route path="/*" element={<MainLayout />} />
            <Route path="/admin/*" element={<AdminLayout />} />
        </Routes>
    );
}

export default Router;