import "./Catalog.css";
import HeaderBack from "../../Images/AboutPage/header-back.png";
import PageDisc from "../../Utils/PageDisc/PageDisc";
import React, { RefObject, useEffect, useRef, useState } from "react";
import { getAllCategories, getAllProducts } from "../../Api/ItemsApi/ItemsApi";
import { Product } from "../../Types";

import arrow from "../../Images/arrow.png";
import Loader from "../../Components/Loader/Loader";
import {useNavigate} from "react-router-dom";
import {setIsCallRequestModalOpened} from "../../Store/Reducers/pageReducer";
import {useDispatch} from "react-redux";

export function Catalog() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const pageDiscRef: RefObject<HTMLDivElement> = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [products, setProducts] = useState<Product[]>([]);
    const [categories, setCategories] = useState<any[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
    const [totalItems, setTotalItems] = useState(0);
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 9,
    });

    const fetchProducts = async () => {
        setIsLoading(true);
        const data = await getAllProducts((pagination.page - 1) * pagination.limit, pagination.limit, selectedCategory);
        setProducts(data.items);
        setTotalItems(data.total);
        setIsLoading(false);
    };

    const fetchCategories = async () => {
        const categoriesData = await getAllCategories();
        setCategories(categoriesData.categories);
        return categoriesData.categories;
    };

    useEffect(() => {
        const checkHash = async () => {
            const categoriesData = await fetchCategories();
            const hash = decodeURIComponent(window.location.hash.replace("#", "")); // Убираем #
            console.log(hash)
            if (hash) {
                const matchedCategory = categoriesData.find((category: any) => category.name === hash); // Ищем категорию по хешу
                if (matchedCategory) {
                    setSelectedCategory(matchedCategory.name);
                    setPagination({ ...pagination, page: 1 });
                }
            }
        };

        checkHash();
    }, []);

    useEffect(() => {
        fetchProducts();
        if (pageDiscRef.current) {
            pageDiscRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }, [pagination, selectedCategory]);

    const handleCategoryClick = (categoryName: string | null) => {
        setSelectedCategory(categoryName);
        setPagination({ ...pagination, page: 1 });
    };

    const handlePageChange = (newPage: number) => {
        setPagination({ ...pagination, page: newPage });
    };

    const totalPages = Math.ceil(totalItems / pagination.limit);

    const renderPageNumbers = () => {
        const pageNumbers = [];
        if (totalPages <= 3) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            pageNumbers.push(1, 2, 3, "...", totalPages);
        }
        return pageNumbers;
    };

    return (
        <>
            <div className={"catalogHeaderWrapper"} style={{
                background: `linear-gradient(0deg, rgba(54, 54, 54, 0.70) 0%, rgba(54, 54, 54, 0.70) 100%),
                url(${HeaderBack}) lightgray 50% / cover no-repeat`
            }}>
                <span className={"catalogHeaderTitle"}>КАТАЛОГ</span>
                <div className={"catalogHeaderContent"}>
                    <span className={"catalogHeaderText"}>Маємо власне виробництво. Підберемо та виготовимо необхідне обладнання.</span>
                    <div className={"catalogHeaderBtns"}>
                        <button className={"catalogHeaderBtn1"} onClick={() => navigate("/services")}>ПРО ПОСЛУГИ</button>
                        <button className={"catalogHeaderBtn2"} onClick={() => dispatch(setIsCallRequestModalOpened(true))}>ЗАМОВИТИ ДЗВІНОК</button>
                    </div>
                </div>
            </div>
            <div ref={pageDiscRef}></div>
            <PageDisc header={"Головна"} current_page={"Каталог"}></PageDisc>
            <div className={"catalogWrapper"}>
                <div className={"catalogNavigation"}>
                    <select
                        onChange={(event) => {
                            const selectedCategory = event.target.value === "Дивитись все" ? null : event.target.value;
                            handleCategoryClick(selectedCategory);
                        }}
                    >
                        <option value="Дивитись все">Дивитись все</option>
                        {categories.map((category) => (
                            <option key={category.id} value={category.name}>
                                {category.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className={"catalogNavigationTablet"}>
                    <span
                        className={`catalogNavigationTabletTitle ${!selectedCategory ? 'catalogNavigationTabletTitleActive' : ''}`}
                        onClick={() => { handleCategoryClick(null) }}
                    >
                        Дивитись все
                    </span>
                    <div className={"catalogNavigationTabletLine"}></div>
                    {categories.map((category) => (
                        <span
                            className={`catalogNavigationTabletCategory ${selectedCategory === category.name ? 'catalogNavigationTabletTitleActive' : ''}`}
                            key={category.id} onClick={() => { handleCategoryClick(category.name) }}
                        >
                            {category.name}
                        </span>
                    ))}
                </div>
                {isLoading ? (
                    <div className={"loaderWrapper"}>
                        <Loader size={100} wid={4} />
                    </div>
                ) : (
                    <div className={"catalogCards"}>
                        {products.length === 0 ? (
                            <div className={"loaderWrapper"}>
                                <span>Товарів не знайдено</span>
                            </div>
                        ) : (
                            products.map((product, index) => (
                                <div key={index} className={"catalogCardWrapper"}>
                                    <img
                                        className={"catalogCardImage"}
                                        src={product.images[0]} alt={product.name}
                                        onClick={() => navigate(`/catalog/${product.id}`)}
                                    />
                                    <div className={"catalogCardContent"}>
                                        <span className={"catalogCardAvailable"}>В наявності</span>
                                        <span className={"catalogCardTitle"}>{product.name}</span>
                                        <button className={"catalogCardBtn"} onClick={() => navigate(`/catalog/${product.id}`)}>ДЕТАЛЬНІШЕ</button>
                                    </div>
                                </div>
                            ))
                        )}

                        {products.length !== 0 &&
                          <div className={"catalogFooter"}>
                            <img
                              onClick={() => handlePageChange(Math.max(1, pagination.page - 1))}
                              style={{ cursor: 'pointer', width: 15, height: 15, rotate: "180deg" }}
                              src={arrow}
                              alt={"<"}
                            />
                              {renderPageNumbers().map((pageNumber, index) => (
                                  <span
                                      key={index}
                                      onClick={() => typeof pageNumber === 'number' && handlePageChange(pageNumber)}
                                      className={"paginationText"}
                                      style={{ cursor: 'pointer', color: pagination.page === pageNumber ? 'black' : '#A9A9A9' }}
                                  >
                                        {pageNumber}
                                    </span>
                              ))}
                            <img
                              onClick={() => handlePageChange(Math.min(totalPages, pagination.page + 1))}
                              style={{ cursor: 'pointer', width: 15, height: 15 }}
                              src={arrow}
                              alt={">"}
                            />
                          </div>
                        }
                    </div>
                )}
            </div>
        </>
    );
}
