import React, { useEffect, useState } from 'react';
import {Category, Product} from "../../../../Types";
import './CategoryTable.css';
import {deleteCategoryById, deleteProductById} from "../../../../Api/ItemsApi/ItemsApi";

interface Props {
    fetchCategories: any;
    onSelect: (category: Category  ) => void;
    categories: Category[];
}

const CategoryTable: React.FC<Props> = ({ categories, onSelect, fetchCategories }) => {

    const handleDelete = async (id: number) => {
        await deleteCategoryById(id);
        await fetchCategories();
    }

    const handleSelect = async (id: number) => {
        const categoryToSelect = categories.find((category) => category.id == id);
        if (categoryToSelect) {
            onSelect(categoryToSelect);
        }
    }

    return (
        <div className="table-container">
            <table>
                <thead>
                    <tr>
                        <th>№</th>
                        <th>Назва</th>
                        <th>Дії</th>
                    </tr>
                </thead>
                <tbody>
                    {categories.map((category, index) => (
                        <tr key={category.id}>
                            <td>{index + 1}</td>
                            <td>{category.name}</td>
                            <td style={{ position: 'relative' }}>
                                <div>
                                    <button onClick={() => handleDelete(category.id)}>Видалити</button>
                                    <button onClick={() => handleSelect(category.id)}>Редагувати</button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default CategoryTable;
