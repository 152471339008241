import { configureStore } from '@reduxjs/toolkit';
import basketReducer from './Reducers/basketReducer';
import pageReducer from "./Reducers/pageReducer";

export type RootState = ReturnType<typeof store.getState>;

const store = configureStore({
    reducer: {
        cart: basketReducer,
        page: pageReducer,
    },
});

export default store;