import React, { useState } from 'react';
import FlexContainer from '../FlexContainer/FlexContainer';
import Text from '../Text/Text';
import './CustomSelect.css';
import { ReactComponent as Down } from '../../Images/Icons/Down.svg';

interface CustomSelectProps {
    title: string;
    option: string;
    options: string[];
    onChange: (value: string) => void;
}

const CustomSelect: React.FC<CustomSelectProps> = ({ title, option, options, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(`Обрати ${option}`);

    const handleOptionClick = (option: string) => {
        setSelectedOption(option);
        onChange(option);
        setIsOpen(false);
    };

    const rotationAngle = isOpen ? '180deg' : '0deg';

    return (
        <div className={"customSelectWrapper"}>
            <Text className="bodyW3" color="#595A5A">
                {title}
            </Text>
            <div className="custom-select" onClick={() => setIsOpen(!isOpen)}>
                <Text className="subtitleW3" styles={{ width: '70%' }}>
                    {selectedOption}
                </Text>
                <Down
                    style={{
                        transform: `rotate(${rotationAngle})`,
                        transition: 'transform 0.5s',
                    }}
                />
            </div>
            {isOpen && (
                <div className="options">
                    {/*<div key={0} className="option" onClick={() => handleOptionClick(`Обрати ${title.toLowerCase()}`)}>*/}
                    {/*    <Text className="bodyW3" color="#8E8E8E">*/}
                    {/*        {`Обрати ${title.toLowerCase()}`}*/}
                    {/*    </Text>*/}
                    {/*</div>*/}
                    {options.map((option, index) => (
                        <div key={index} className="option" onClick={() => handleOptionClick(option)}>
                            <Text className="bodyW3" color="#8E8E8E">
                                {option}
                            </Text>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CustomSelect;
