import React from 'react';
import "./ServiceCard.css";
import {useNavigate} from "react-router-dom";

interface ServiceCardProps {
    text: string;
    url: string;
    button_text: string;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ text, url, button_text }) => {
    const navigate = useNavigate();
    return (
        <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
            <div id={"service-card-button"}>
                <span id={"service-card-text"}>{text}</span>
                <button onClick={() => navigate(url)} id={"service-card-action"}>
                    {button_text}
                    <svg className={"service-card-svg"} width="31" height="14" viewBox="0 0 31 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M22.3534 11.4883C21.9321 11.8455 21.8801 12.4765 22.2372 12.8978C22.5943 13.3191 23.2253 13.3711 23.6466 13.014L29.8416 7.76294C30.0657 7.57294 30.195 7.29396 30.195 7.00011C30.195 6.70626 30.0657 6.42728 29.8416 6.23728L23.6466 0.986214C23.2253 0.629107 22.5943 0.681146 22.2372 1.10245C21.8801 1.52374 21.9321 2.15477 22.3534 2.51187L26.4582 5.99124L1 5.99124C0.447715 5.99124 0 6.43896 0 6.99124C0 7.54353 0.447715 7.99124 1 7.99124L26.4791 7.99124L22.3534 11.4883Z" fill="#595A5A"/>
                    </svg>
                </button>
            </div>
        </div>
    );
};

export default ServiceCard;
