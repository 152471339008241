import {FC, useEffect, useState} from "react";
import "./AdminCategories.css";
import CategoryTable from "../Tables/CategoryTable/CategoryTable";
import CategoryForm from "../Forms/CategoryForm/CategoryForm";
import {Category, Product} from "../../../Types";
import {getAllCategories} from "../../../Api/userRequestsApi/userRequestsApi";

export const AdminCategories: FC = () => {
    const [categories, setCategories] = useState<Category[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState<Category | undefined>(undefined);

    const fetchCategories = async () => {
        const data = await getAllCategories();
        setCategories(data.categories);
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    const handleClose = () => {
        fetchCategories();
        setIsModalOpen(false)
    }

    const handleCreateNewCategory = () => {
        setSelectedCategory(undefined);
        setIsModalOpen(true);
    };


    const handleSelectCategory= (category: Category) => {
        setSelectedCategory(category);
        setIsModalOpen(true);
    }

    return (
        <>
            <div className={"adminPanelHeader"}>
                <span className={"adminCategoriesHeader"}>Категорії</span>
                <button className={"createButton"} onClick={handleCreateNewCategory}>Створити категорію</button>
            </div>
            <section id="products">
                <CategoryTable categories={categories} onSelect={handleSelectCategory} fetchCategories={fetchCategories}></CategoryTable>
            </section>
            {isModalOpen && <CategoryForm onClose={handleClose} selectedCategory={selectedCategory}/>}
        </>
    );
};
