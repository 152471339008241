import { FC } from 'react';
import './Loader.css';

interface LoaderProps {
  size?: number;
  wid?: number;
}

const Loader: FC<LoaderProps> = ({ size, wid }) => {
  return (
      <div
          className="spinner"
          style={{ width: size, maxWidth: size, minWidth: size, height: size, minHeight: size, maxHeight: size }}
      >
        <div
            className="spinner-circle"
            style={{
              border: `${wid}px solid #ececf1`,
              borderLeftColor: '#F37E3C',
            }}
        ></div>
      </div>
  );
};

export default Loader;
