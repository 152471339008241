import React from 'react';
import "./PageDisc.css";

interface PageDiscProps {
    header: string;
    current_page: string;
}

const PageDisc: React.FC<PageDiscProps> = ({header, current_page}) => {
    return (
        <div id={"disc-container"}>
            <span id={"disc-text"}>
                {header} | {current_page}
            </span>
        </div>
    );
};

export default PageDisc;