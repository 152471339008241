import React, {FC, useEffect, useState} from 'react';
import {deleteProductById, getAllProducts} from "../../../../Api/ItemsApi/ItemsApi";
import {Product} from "../../../../Types";

interface ProductTableProps {
    onSelect: (product: Product  ) => void;
}

const ProductTable: FC<ProductTableProps> = ({ onSelect }) => {
    const [products, setProducts] = useState<Product[]>([]);
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    });

    const fetchProducts = async () => {
        const data = await getAllProducts((pagination.page - 1) * pagination.limit, pagination.limit, null);
        setProducts(data.items);
    };

    useEffect(() => {
        fetchProducts();
    }, [pagination]);

    const handlePaginationChange = (newPage: number) => {
        setPagination((prev) => ({ ...prev, page: newPage }));
    };

    const handlePageSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newLimit = parseInt(event.target.value, 10);
        setPagination((prev) => ({ ...prev, limit: newLimit, page: 1 }));
    };

    const handleDelete = async (id: number) => {
        await deleteProductById(id);
        await fetchProducts();
    }

    const handleSelect = async (id: number) => {
        const productToSelect = products.find((product) => product.id == id);
        if (productToSelect) {
            onSelect(productToSelect);
        }
    }

    return (
        <>
            <div className="table-container">
                <table>
                    <thead>
                    <tr>
                        <th>Id</th>
                        <th>Назва</th>
                        <th>Опис</th>
                        <th>Картинки</th>
                        <th>Ширина</th>
                        <th>Довжина</th>
                        <th>Товщина</th>
                        <th>Діаметр</th>
                        <th>Діаметр1</th>
                        <th>Діаметр2</th>
                        <th>Градус</th>
                        <th>Тип</th>
                        <th>Підкатегорія</th>
                        <th>Дії</th>
                    </tr>
                    </thead>
                    <tbody>
                    {products.map((product) => (

                        <tr key={product.id}>
                            <td>{product.id}</td>
                            <td>{product.name}</td>
                            <td>{product.description}</td>
                            <td>{
                                product.images.map((image) => (
                                    <img width={40} height={40} src={image} alt={"error"}/>
                                ))
                            }</td>
                            <td>{product.width}</td>
                            <td>{product.len}</td>
                            <td>{product.thickness}</td>
                            <td>{product.diameter}</td>
                            <td>{product.diameter1}</td>
                            <td>{product.diameter2}</td>
                            <td>{product.angle}</td>
                            <td>{product.item_type}</td>
                            <td>{product.subcategory_name}</td>
                            <td style={{ position: 'relative' }}>
                                <div>
                                   <button onClick={() => handleDelete(product.id!)}>Видалити</button>
                                   <button onClick={() => handleSelect(product.id!)}>Редагувати</button>
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div className="pagination">
                <button onClick={() => handlePaginationChange(pagination.page - 1)} disabled={pagination.page === 1}>
                    Назад
                </button>
                <span>{pagination.page}</span>
                <button onClick={() => handlePaginationChange(pagination.page + 1)}>Вперед</button>
            </div>
            <div className="page-size">
                <label htmlFor="pageSize">Записів на сторінці:</label>
                <select id="pageSize" value={pagination.limit} onChange={handlePageSizeChange}>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                </select>
            </div>
        </>
    );
};

export default ProductTable;

