import {NavLink} from "react-router-dom";
import {FC} from "react";
import "./AdminNavbar.css";

const AdminNavbar: FC = () => {
    return (
        <div className={"adminNavbar"}>
            <NavLink className={"adminNavLink"} to="/admin/categories">Категорії</NavLink>
            <NavLink className={"adminNavLink"} to="/admin/subcategories">Підкатегорії</NavLink>
            <NavLink className={"adminNavLink"} to="/admin/products">Товари</NavLink>
            <NavLink className={"adminNavLink"} to="/admin/requests">Запити на консультацію</NavLink>
            <NavLink className={"adminNavLink"} to="/admin/pricerequests">Запити на ціну</NavLink>
            <NavLink className={"adminNavLink"} to="/admin/orders">Замовлення</NavLink>
        </div>
    );
};

export default AdminNavbar;