import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {ProductOrder} from "../../Types";

interface PageState {
    isPriceRequestModalOpened: boolean;
    isCallRequestModalOpened: boolean;
    selectedProductName: string;
}

const initialState: PageState = {
    isPriceRequestModalOpened: false,
    isCallRequestModalOpened: false,
    selectedProductName: ""
}



const pageSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        setIsPriceRequestModalOpened(state, action: PayloadAction<boolean>) {
            state.isPriceRequestModalOpened = action.payload
        },
        setIsCallRequestModalOpened(state, action: PayloadAction<boolean>) {
            state.isCallRequestModalOpened = action.payload
        },
        setSelectedProductName(state, action: PayloadAction<string>) {
            state.selectedProductName = action.payload
        },
    },
});

export const {
    setIsPriceRequestModalOpened,
    setIsCallRequestModalOpened,
    setSelectedProductName
} = pageSlice.actions;

export default pageSlice.reducer;
