import React, {useState} from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import './Navigation.css';
import ShoppingCart from "../../Utils/ShoppingCart/Basket";
import Search from "../../Utils/Search/Search";
import Group from "../../Images/Logos/Group1.png";
import GroupSmall from "../../Images/Logos/Group.png";
import FlexContainer from "../../Utils/FlexContainer/FlexContainer";


const Navbar: React.FC = () => {
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleLinkClick = (path: string) => {
        navigate(path);
        setMenuOpen(false);
    };

    return  (
        <>
        <div className="navWrapper">
            <img src={GroupSmall} alt="Error" onClick={() => handleLinkClick("/")} />
            <span className="menu" onClick={toggleMenu}>Меню</span>
            <ShoppingCart />
            <div className={`menuLinks ${menuOpen ? "show" : ""}`}>
                <span onClick={() => handleLinkClick("/")} className="nav-link">Головна</span>
                <span onClick={() => handleLinkClick("/about")} className="nav-link">Про компанію</span>
                <span onClick={() => handleLinkClick("/services")} className="nav-link">Послуги</span>
                <span onClick={() => handleLinkClick("/catalog")} className="nav-link">Каталог</span>
                <span onClick={() => handleLinkClick("/contact")} className="nav-link">Контакти</span>
            </div>
        </div>
        <div className="navigationWrapper">
            <img src={Group} alt={'Error'} className={"logo"}/>
            <img src={GroupSmall} alt={'Error'} className={"smallLogo"}/>
            <div className={"navigationLinks"}>
                <NavLink to="/" end className="nav-link">Головна</NavLink>
                <NavLink to="/about" className="nav-link">Про компанію</NavLink>
                <NavLink to="/services" className="nav-link">Послуги</NavLink>
                <NavLink to="/catalog" className="nav-link">Каталог</NavLink>
                <NavLink to="/contact" className="nav-link">Контакти</NavLink>
            </div>
            <ShoppingCart></ShoppingCart>
        </div>
        </>
    )
};

export default Navbar;