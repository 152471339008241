import "./BasketOrderInfo.css";
import React, {useState} from "react";
import {createOrder, createPriceRequest} from "../../Api/userRequestsApi/userRequestsApi";
import {useSelector} from "react-redux";
import {RootState} from "../../Store/store";

const BasketOrderInfo = () => {
    const [disabled, setDisabled] = useState(false);
    const [textButton, setTextButton] = useState('ПІДТВЕРДИТИ');
    const [username, setUsername] = useState('');
    const [contactInfo, setContactInfo] = useState('');
    const [extraInfo, setExtraInfo] = useState('');
    const items = useSelector((state: RootState) => state.cart.items);

    const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(event.target.value);
    };

    const handleContactInfoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContactInfo(event.target.value);
    };

    const handleExtraInfoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setExtraInfo(event.target.value);
    };


    const handleSubmit = async () => {
        if (username.trim() !== '' || contactInfo.trim() !== '' || extraInfo.trim() !== '') {
            await createOrder({username: username, contactInfo: contactInfo, extraInfo: extraInfo, items: JSON.stringify(items)});
            setTextButton("Замовлення відправлено")
            setDisabled(true);
        }
    };


    return (
        <div className={"basketOrderInfoWrapper"}>
            <span className={"basketOrderTitle"}>Замовити ціну</span>
            <div className={"basketOrderLine"}/>
            <div className={"basketOrderSummary"}>
                <div className={"basketOrderSummaryBlock"}>
                    <span>Ім'я</span>
                    <input
                        value={username}
                        onChange={handleUsernameChange}
                        placeholder={"Введіть ваше ім'я"}
                    />
                </div>
                <div className={"basketOrderSummaryBlock"}>
                    <span>Телефон або пошта</span>
                    <input
                        value={contactInfo}
                        onChange={handleContactInfoChange}
                        placeholder={"Введіть телефон або пошту"}
                    />
                </div>
                <div className={"basketOrderSummaryBlock"}>
                    <span>Примітка</span>
                    <input
                        value={extraInfo}
                        onChange={handleExtraInfoChange}
                        placeholder={"Введіть доп. інформацію"}
                    />
                </div>
            </div>
            <div className={"basketOrderLine"}/>
            <span className={"basketOrderInfo"}>Менеджер з'явжиться з вами найближчим часом.</span>
            <button className={"basketOrderSubmitBtn"} onClick={handleSubmit} disabled={disabled}>{textButton}</button>
        </div>
    )
}

export default BasketOrderInfo